import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {HttpService} from 'src/app/_services/HttpService';
import {IfcsLoadingUIService, IfcsModalService, UtilFuncService, IfcsComponentBase, LanguageService, IfcsTableService} from "ifocus-lib";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultLanguage, getTransMessage } from "../../../../utils/CommonFunction";

@Component({
    selector: 'app-device-message',
    templateUrl: './device-message.component.html',
    styleUrls: ['./device-message.component.css']
})
export class DeviceMessageComponent extends IfcsComponentBase implements OnInit, OnDestroy {

    @Input() selectedRow: any;
    @Output() closeEvent = new EventEmitter();

    messageToBeSend: string;
    messageList: any = {
        total: 0,
        pageNumber: 1,
        pageSize: 5,
        list: [],
    };

    constructor(private httpService: HttpService,
                private utilFuncService: UtilFuncService,
                public loader: LoadingBarService,
                public loadingService: IfcsLoadingUIService,
                protected language: LanguageService,
                protected translate: TranslateService,
                private tableService: IfcsTableService,
                ) {
        // 多言語対応
        super(language, translate);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getMessageList();
        });
    }

    ngOnDestroy(): void {
        this.loader.complete()
        this.loadingService.complete();
    }

    async getMessageList() {

        this.loader.start();
        this.loadingService.start();

        var page = await this.httpService.useGet(
            "/downlinkMessage/" + this.selectedRow.imei
            + "/list?pageSize=" + this.messageList.pageSize
            + "&pageNumber=" + this.messageList.pageNumber);
        this.messageList.total = page.total;
        this.messageList.list = page.currentPage;

        this.loader.complete();
        this.loadingService.complete();

    }

    async sendMessageToDevice(noRefresh: boolean) {

        if (this.messageToBeSend == null || this.messageToBeSend.length == 0) {
            this.utilFuncService.showAlert('warn', getTransMessage(this.translate, "pleaseEnterAMessage"));
            throw new Error("MESSAGE_EMPTY");
        }

        this.loader.start();
        this.loadingService.start();

        try {
            var result = await this.httpService.usePost("downlinkMessage/" + this.selectedRow.imei + "/send", {
                message: this.messageToBeSend
            });
            if (result) {
                this.messageToBeSend = null;
                this.utilFuncService.showAlert('success',getTransMessage(this.translate, "messageSentSuccessfully") +"(imei=" + this.selectedRow.imei + ")");
            } else {
                this.utilFuncService.showAlert('error', getTransMessage(this.translate, "messageSendingFailed")+"(imei=" + this.selectedRow.imei + ")"+getTransMessage(this.translate, "isTheDeviceOnline"));
            }
        } catch (e) {
            console.log(e);
            // console.log('メッセージ送信　APIエラー発生しました。');
        }
        if (!noRefresh) {
            this.getMessageList();
        }

        this.loader.complete();
        this.loadingService.complete();

    }


    onMessageListPageChange(targetPageNumber: any) {
        this.messageList.list = [];
        this.messageList.pageNumber = targetPageNumber;
        this.getMessageList();
    }

    closeModal(): void {
        this.closeEvent.emit(null);
    }

}
