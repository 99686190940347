<div class="app-content content" *ngIf="isPageLoaded">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0"
                             style="background-image: url(../../assets/custom/images/signin-background1.png);">
                            <div class="card-header border-0"
                                 style="background-image: url(../../assets/custom/images/signin-background1.png);">
                                <div class="card-title text-center">
                                    <img src="assets/custom/images/logo.png" alt="branding logo" width="35%"
                                         height="35%">
                                </div>
                            </div>
                            <div class="card-title text-center">
                                <h1 style="color:white;">AAASWatch</h1>
                            </div>
                            <div class="card-content">
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                </p>
                                <div class="card-body" *ngIf="loginType ==='email'">
                                    <div>
                                        <!-- 言語 -->
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <select class="form-control"
                                                    (change)=onChangeSelect($event)
                                                    [(ngModel)]="selectedLocaleId">
                                                <option *ngFor="let l of localeList" [value]="l.localeId">{{l.localeName}}</option>
                                            </select>

                                            <div class="form-control-position">
                                                <i class="la la-opera"></i>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="email" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                   id="user-name"
                                                   placeholder="{{'mailAddr' | translate}}" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">{{'msg_inputMailAddr'}}</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" placeholder="{{'password' | translate}}"
                                                   formControlName="password" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">{{'msg_inputPassword'}}</div>
                                            </div>

                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left" style="display: flex;">
                                            <input type="text" formControlName="checkcode" class="form-control"
                                                   [ngClass]="{ 'is-invalid': submitted && f.checkcode.errors }"
                                                   style="border-bottom-right-radius: 0%; border-top-right-radius: 0%;"
                                                   placeholder="{{'checkCode' | translate}}" required>
                                            <div class="form-control-position">
                                                <i class="la la-lock"></i>
                                            </div>
                                            <div *ngIf="submitted && f.checkcode.errors" class="invalid-feedback">
                                                <div *ngIf="f.checkcode.errors.required">{{'inputCheckCode'}}</div>
                                            </div>
                                            <div class="verifyDiv float-left" 
                                                style="border-bottom-right-radius: 0.25rem; border-top-right-radius:  0.25rem;"
                                                (click)="resetCheckCode()">
                                                <canvas #myCanvas class="verifyCanvas"></canvas>
                                            </div>
                                        </fieldset>

                                        <fieldset class="form-group position-relative has-icon-left">
                                            <button type="submit" class="btn btn-outline-info btn-block">
                                                <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                                <i class="feather ft-unlock" *ngIf="!submitted"></i>
                                                <span translate>&nbsp;login</span>
                                            </button>
                                        </fieldset>

                                        <fieldset class="form-group position-relative has-icon-left">
                                            <ifcs-badge label="{{'resendEmailVerify' | translate}}"
                                                        (clickEvent)="resendMail($event)"
                                                        linkClass="badge block badge-primary resendMail"
                                                        linkStyle="background-color: #ef9a9a"
                                                        iconClass="la icon-envelope-open font-medium-2">
                                            </ifcs-badge>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <ifcs-badge label="{{'resettingPassword' | translate}}"
                                                        (clickEvent)="restPassword($event)"
                                                        linkClass="badge block badge-primary restPassword"
                                                        linkStyle="background-color: #b388ff"
                                                        iconClass="la icon-bulb font-medium-2">
                                            </ifcs-badge>
                                        </fieldset>

                                    </form>
                                </div>

                                <div class="card-body" *ngIf="loginType ==='sms'">
                                    <fieldset class="form-group position-relative has-icon-left mb15">
                                        <input type="text" class="form-control" [(ngModel)]="phoneNumber" readonly
                                            placeholder="{{'mobileNumber' | translate}}" required>
                                        <div class="form-control-position">
                                            <i class="la la-user"></i>
                                        </div>
                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left mb15"
                                        style="display: flex;">
                                        <input type="text" class="form-control" id="code" maxlength="6"
                                            style="border-bottom-right-radius: 0%; border-top-right-radius: 0%;"
                                            placeholder="{{'verificationCode' | translate}}" required
                                            [(ngModel)]="phoneCode">
                                        <div class="form-control-position">
                                            <i class="la la-lock"></i>
                                        </div>
                                        <button class="form-control btn btn-primary p0" (click)="onSendCode()"
                                            [disabled]="sendcode">
                                            <span translate class="row">{{'send'}}<div *ngIf="sendcode">({{ countdown }})</div></span>
                                        </button>

                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left mb15">
                                        <button type="button" class="btn btn-outline-info btn-block"
                                            (click)="onPhoneNumberLogin()" [disabled]="phoneSubmitted">
                                            <i class="fa fa-refresh fa-spin" *ngIf="phoneSubmitted"></i>
                                            <i class="feather ft-unlock" *ngIf="!phoneSubmitted"></i>
                                            <span translate>&nbsp;{{'login'}}</span>
                                        </button>
                                    </fieldset>

                                    <fieldset class="form-group position-relative has-icon-left">
                                        <button class="btn btn-outline-info btn-block"
                                            (click)="onEmailLoginClick()">
                                            <i class="feather ft-mail"></i>
                                            <span translate>&nbsp;{{'back'}}</span>
                                        </button>
                                    </fieldset>
                                </div>

                                <!-- 二段階認証　(メール) -->
                                <app-two-factor-auth
                                    *ngIf="loginType === '2faEmail'"
                                    [email]="email"
                                    [signInToken]="signInToken"
                                    (doLoginEvent)="doLoginOutput($event)">
                                </app-two-factor-auth>     

                                <div id="recaptcha-container"></div>
                                <!-- <div class="card-body">
                                    <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                            class="la la-user"></i> 新規登録</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
<p-toast position="top-right" key="alertModal" baseZIndex="999"></p-toast>
<!--<p-confirmDialog-->
<!--        [baseZIndex]="10000" rejectButtonStyleClass="p-button-secondary"-->
<!--        icon="pi pi-exclamation-triangle"-->
<!--        acceptLabel="確認"-->
<!--        rejectLabel="取消">-->
<!--</p-confirmDialog>-->
