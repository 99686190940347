import {environment} from '../../../environments/environment';

export class ConstantsHandler {

    // サーバー
    static server = environment.accUrl.server;
    // BSサーバー
    static bsServer = environment.accUrl.bsServer;
    // キークロックサーバー
    static keycloakServer = environment.keycloak.server;
    // キーId
    static keycloak_client_id = environment.keycloak.clientId;

    static targetuserid = environment.targetUser.id;
    static targetuserCompanyid = environment.targetUser.companyId;

    static watchServer = environment.httpEnv.watchServer;

    static USER_TOKEN_INVALID = 'ユーザー安全認証トークンが無効になりました';
    static SUCCESS_INFO = 'SUCCESS';

    static multiLanguage = environment.multiLanguage;
    static LOCAL_STORAGE = environment.other.LOCAL_STORAGE;

    static PIC_ONLINE = "assets/marker/header-man-background-green.svg";
    static PIC_ONLINE_NOT_WEARING = "assets/marker/header-man-background-grey.svg";
    static PIC_ONLINE_NO_LOCATION = "assets/marker/header-man-background-green-dashed.svg";
    static PIC_OFFLINE = "assets/marker/header-man-background-light-grey.svg";
    static PIC_ALERT = "assets/marker/header-man-background-red.svg";
    static PIC_ALERT_NO_LOCATION = "assets/marker/header-man-background-red-dashed.svg";


    // static server = "https://demo.trackun.jp/iotPF";
    // static server = "https://demo.trackun.jp/v1.0";
    // static bsServer = "http://localhost:8081";
    // static keycloakServer = "https://auth.trackun.jp/auth/realms/trackun/"
    // static keycloakServer = "https://auth.trackun.jp/"
    // static keycloakServer = "http://localhost:8080/auth/realms/trackun/"
    // サーバー（リリース）
    // static watchServer = "http://47.91.30.55/";


    static TOKEN = {
        cookieName: "ACCESS_TOKEN",
        access_token: "",
        access_token_fetch_time: 0,
        access_token_valid_time: 0,
        refresh_token: "",
        refresh_token_fetch_time: 0,
        refresh_token_valid_time: 0
    }

    static tokenStatus = {
        access_token_valid: 1,
        refresh_token_valid: 2,
        refresh_token_expired: 3
    }

    static GLOBAL_TOKEN = {
        id: 'PF_GLOBAL_TOKEN',
        errMsg: {
            userTokenInvalid: 'ユーザー安全認証トークンが無効になりました',
            userPasswordInvalid: 'ユーザー名とパスワードが間違います'
        },
        interval: 6 * 60 * 60 * 1000,
        timeZone: 9 * 60 * 60 * 1000
    };

    static EMAIL_EXPIRE_TOKEN = {
        id: '2FA_EMAIL_TOKEN',
        interval: 7 * 24 * 60 * 60 * 1000
    }

    static SMS_EXPIRE_TOKEN = {
        id: '2FA_SMS_TOKEN',
        interval: 7 * 24 * 60 * 60 * 1000
    }

}

export class ServerType {
    static loginServer = 1;
    static server = 2;
    static bsServer = 3;
}
