import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavbarService} from '../../../_services/navbar.service';
import {ThemeSettingsService} from '../../settings/theme-settings.service';
import {MenuSettingsService} from '../../settings/menu-settings.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from 'src/app/_services/auth.service';
import {Router} from '@angular/router';
import {
    PerfectScrollbarComponent,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import {AppConstants} from 'src/app/_helpers/app.constants';
import {HttpService} from 'src/app/_services/HttpService';
import {VerticalService} from './vertical.service';
import {DateUtilService, IfcsComponentBase, IfcsModalService, LanguageService} from "ifocus-lib";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HelpModalService} from "../../../content/components/help-modal/help-modal.service";
import {UserService} from 'src/app/_services/UserService';
import {TranslateService} from "@ngx-translate/core";
import {getTransMessage} from "../../../utils/CommonFunction";
import {SelfUserInfoService} from "../../../utils/SelfUserInfoService";
import {BusinessUtils} from "../../../utils/BusinessUtils";

const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
};

const docWithBrowsersExitFunctions = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    webkitExitFullscreen(): Promise<void>;
    msExitFullscreen(): Promise<void>;
};

/**
 * 多言語情報
 */
export class LocaleInfo {
    // 詳細リスト
    localeList: any[] = [];
}


@Component({
    selector: 'app-header-vertical',
    templateUrl: './vertical.component.html',
    styleUrls: ['./vertical.component.css']
})
export class VerticalComponent extends IfcsComponentBase implements OnInit, AfterViewInit, OnDestroy {
    public username: any;
    insideTm: any;
    outsideTm: any;
    private _unsubscribeAll: Subject<any>;
    private _unsubscribeAllMenu: Subject<any>;
    public _themeSettingsConfig: any;
    private _menuSettingsConfig: any;
    public selectedHeaderNavBarClass: string;
    public selectedNavBarHeaderClass: string;
    public currentUser: any;
    public isHeaderSearchOpen: any;
    isMobile = false;
    showNavbar = false;
    public maximize: any;
    public search: any;
    public internationalization: any;
    public notification: any;
    public email: any;
    public alertAnread: any;
    alertUnreadList: any[] = [];
    public noticeAnread: any;
    noticeUnreadList: any[] = [];
    read = 0;
    noticeList = [];
    noticeReadList = [];
    autoRefresh: boolean = false;
    timer: any;
    helpDoc: string;
    avatarUrl = "../../../assets/images/portrait/small/default.png";

    locale: any;
    localeInfo: LocaleInfo = new LocaleInfo();

    public config: PerfectScrollbarConfigInterface = {wheelPropagation: false};
    @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, {static: true}) directiveRef?: PerfectScrollbarDirective;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _renderer: Renderer2,
        private navbarService: NavbarService,
        private _themeSettingsService: ThemeSettingsService,
        private _menuSettingsService: MenuSettingsService,
        public authService: AuthService,
        private router: Router,
        private elementRef: ElementRef,
        private httpService: HttpService,
        private verticalService: VerticalService,
        // private angularFireMessaging: AngularFireMessaging,
        private modalService: IfcsModalService,
        private helpModalService: HelpModalService,
        private modalService1: NgbModal,
        private userService: UserService,
        private selfUserInfoService: SelfUserInfoService,
        protected language: LanguageService,
        protected translate: TranslateService,
    ) {
        super(language, translate);

        this._unsubscribeAll = new Subject();
        this._unsubscribeAllMenu = new Subject();
        this.verticalService.updateAlertCall$.subscribe(() => {
            this.getAlertUnreadOnly();
        });
        this.verticalService.updateNoticeCall$.subscribe(() => {
            this.getNoticeUnreadOnly();
        });
    }

    ngOnDestroy(): void {
        this.stopTimer();
    }

    logout() {
        if (localStorage.getItem('currentUser')) {
            this.authService.doLogout().then(res => {
                window.location.href = '/';
            }, err => {
                console.log(err);
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();

        // ロケールリスト
        this.getLocaleInfo();

        this.getAlertUnreadOnly();
        this.getNoticeUnreadOnly();
        this.getProfileUserInfo();

        this.isMobile = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;
        if (!this.isMobile) {
            this.showNavbar = true;
        }
        if (localStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        // Subscribe to config changes
        this._themeSettingsService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this._themeSettingsConfig = config;
                this.refreshView();
            });
        this._menuSettingsService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this._menuSettingsConfig = config;
            });
        this.maximize = this._themeSettingsConfig.headerIcons.maximize;
        this.search = this._themeSettingsConfig.headerIcons.search;
        this.internationalization = this._themeSettingsConfig.headerIcons.internationalization;
        this.notification = this._themeSettingsConfig.headerIcons.notification;
        this.email = this._themeSettingsConfig.headerIcons.email;
        // this.toggleFixMenu(null);

        if (localStorage.getItem("AutoRefresh")) {
            this.autoRefresh = true;
            this.startTimer();
        }
    }

    getAlertUnreadOnly() {
        // 'user-notification/alert/list?unreadOnly=true'
        this.httpService.useGet('user-notification/alert/list?pageSize=9999&pageNumber=1&unreadOnly=true').then(result => {
            try {
                if (result) {
                    this.alertAnread = result.total;
                    result.currentPage.forEach(item => {
                        item.createdTimestamp = BusinessUtils.convertDateTimeForTimezone(item.createdTimestamp);
                        item.recordCreatedTimestamp = BusinessUtils.convertDateTimeForTimezone(item.recordCreatedTimestamp);
                        item.webPushTs = BusinessUtils.convertDateTimeForTimezone(item.webPushTs);
                    });

                    this.alertUnreadList = result.currentPage.slice(0, 5);
                    // this.unread = this.noticeList.length - this.read
                    localStorage.setItem("globalAlertList", JSON.stringify(result.currentPage));
                }
            } catch (e) {
                console.log(e);
                console.log('エラー　発生しました。');
            }
        });
    }

    getNoticeUnreadOnly() {
        this.httpService.useGet('user-notification/notice/list?unreadOnly=true').then(result => {
            try {
                if (result) {
                    this.noticeAnread = result.total;
                    this.noticeUnreadList = result.currentPage.slice(0, 5);
                    // this.unread = this.noticeList.length - this.read

                    this.noticeUnreadList.forEach(item => {
                        item.createdTimestamp = BusinessUtils.convertDateTimeForTimezone(item.createdTimestamp);
                        item.recordCreatedTimestamp = BusinessUtils.convertDateTimeForTimezone(item.recordCreatedTimestamp);
                        item.webPushTs = BusinessUtils.convertDateTimeForTimezone(item.webPushTs);
                    });
                }
            } catch (e) {
                console.log(e);
                console.log('エラー　発生しました。');
            }
        });
    }

    ngAfterViewInit(): void {
        this.refreshView();
    }

    refreshView() {
        const iconElement = document.getElementsByClassName('toggle-icon');
        const menuColorElement = document.getElementsByClassName('main-menu');
        const navigationElement = document.getElementsByClassName('main-menu');
        const navbarElement = document.getElementsByClassName('header-navbar');
        const themeColorElement = document.getElementsByClassName('header-navbar');
        const element = document.getElementsByClassName('navbar-header');
        const boxelement = document.getElementById('customizer');
        if (iconElement) {
            if (this._themeSettingsConfig.colorTheme === 'semi-light' || this._themeSettingsConfig.colorTheme === 'light') {
                this._renderer.removeClass(iconElement.item(0), 'white');
                this._renderer.addClass(iconElement.item(0), 'blue-grey');
                this._renderer.addClass(iconElement.item(0), 'darken-3');
            } else if (this._themeSettingsConfig.colorTheme === 'semi-dark' || this._themeSettingsConfig.colorTheme === 'dark') {
                this._renderer.addClass(iconElement.item(0), 'white');
                this._renderer.removeClass(iconElement.item(0), 'blue-grey');
                this._renderer.removeClass(iconElement.item(0), 'darken-3');
            }
        }

        if (this._themeSettingsConfig.colorTheme === 'semi-light') {
            this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
            this.selectedNavBarHeaderClass = '';
        } else if (this._themeSettingsConfig.colorTheme === 'semi-dark') {
            this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
            this.selectedHeaderNavBarClass = '';
        } else if (this._themeSettingsConfig.colorTheme === 'dark') {
            this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
            this.selectedNavBarHeaderClass = '';
        } else if (this._themeSettingsConfig.colorTheme === 'light') {
            this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
            this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
        }
        if (menuColorElement) {
            if (this._themeSettingsConfig.menuColor === 'menu-dark') {
                this._renderer.removeClass(menuColorElement.item(0), 'menu-light');
                this._renderer.addClass(menuColorElement.item(0), 'menu-dark');
            } else if (this._themeSettingsConfig.menuColor === 'menu-light') {
                this._renderer.removeClass(menuColorElement.item(0), 'menu-dark');
                this._renderer.addClass(menuColorElement.item(0), 'menu-light');
            }
        }

        if (themeColorElement) {
            if (this._themeSettingsConfig.colorTheme === 'semi-light') {
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
            } else if (this._themeSettingsConfig.colorTheme === 'semi-dark') {
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-light');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
            } else if (this._themeSettingsConfig.colorTheme === 'dark') {
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
            } else if (this._themeSettingsConfig.colorTheme === 'light') {
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
                this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-light');
            }
        }

        if (navigationElement) {
            if (this._themeSettingsConfig.navigation === 'menu-native-scroll') {
                this._renderer.addClass(navigationElement.item(0), 'menu-native-scroll');
            } else if (this._themeSettingsConfig.navigation === 'menu-icon-right') {
                this._renderer.addClass(navigationElement.item(0), 'menu-icon-right');
            } else if (this._themeSettingsConfig.navigation === 'menu-bordered') {
                this._renderer.addClass(navigationElement.item(0), 'menu-bordered');
            } else if (this._themeSettingsConfig.navigation === 'menu-flipped') {
                this._renderer.addClass(document.body, 'menu-flipped');
            } else if (this._themeSettingsConfig.navigation === 'menu-collapsible') {
                this._renderer.addClass(navigationElement.item(0), 'menu-collapsible');
            } else if (this._themeSettingsConfig.navigation === 'menu-static') {
                this._renderer.addClass(navigationElement.item(0), 'menu-static');
            }
        }

        if (navbarElement) {
            if (this._themeSettingsConfig.menu === 'navbar-static-top') {
                this._renderer.addClass(navbarElement.item(0), 'navbar-static-top');
                this._renderer.addClass(navigationElement.item(0), 'menu-static');
            }
        }

        if (navbarElement) {
            if (this._themeSettingsConfig.menu === 'semi-light') {
                this._renderer.addClass(navbarElement.item(0), 'navbar-semi-light bg-gradient-x-grey-blue');
            } else if (this._themeSettingsConfig.menu === 'semi-dark') {
                this._renderer.addClass(navbarElement.item(0), 'navbar-semi-dark');
            } else if (this._themeSettingsConfig.menu === 'dark') {
                this._renderer.addClass(navbarElement.item(0), 'navbar-dark');
            } else if (this._themeSettingsConfig.menu === 'light') {
                this._renderer.addClass(navbarElement.item(0), 'navbar-light');
            }
        }
    }

    resetOpenMenu() {
        for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
            const menu = this._menuSettingsConfig.vertical_menu.items[i];
            if (!menu.submenu) {
                menu['isOpen'] = false;
                menu['isActive'] = false;
                menu['hover'] = false;
            } else if (menu.submenu) {
                for (let j = 0; j < menu.submenu.items.length; j++) {
                    menu['isOpen'] = false;
                    menu['isActive'] = false;
                    menu['hover'] = false;
                    menu.submenu.items[j]['isOpen'] = false;
                }
            }
        }
    }

    setOpenInNavbar(value) {
        for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
            const menu = this._menuSettingsConfig.vertical_menu.items[i];
            if (!menu.submenu &&
                menu.page === this.router.url) {
                menu['isOpen'] = value;
                menu['isActive'] = value;
            } else if (menu.submenu) {
                for (let j = 0; j < menu.submenu.items.length; j++) {
                    if (menu.submenu.items[j].page === this.router.url) {
                        menu['isOpen'] = value;
                        menu['isActive'] = value;
                        menu.submenu.items[j]['isOpen'] = value;
                        menu.submenu.items[j]['isActive'] = value;
                        break;
                    }
                }
            }
        }
    }

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    mouseEnter(e) {
        if (this.navbarService.isFixedMenu()) {
            return;
        }
        this.navbarService.setMouseInRegion(true);
        const navBar = this.document.getElementById('navbar-header');
        const mainMenu = this.document.getElementById('main-menu');

        // check if the left aside menu is fixed
        if (!navBar.classList.contains('expanded')) {
            this._renderer.addClass(navBar, 'expanded');
            this._renderer.addClass(mainMenu, 'expanded');
            this.resetOpenMenu();
            this.setOpenInNavbar(true);
        }
    }

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    mouseLeave(event) {
        if (this.navbarService.isFixedMenu()) {
            return;
        }
        const _self = this;
        const navBar = this.document.getElementById('navbar-header');
        const mainMenu = this.document.getElementById('main-menu');
        if (navBar && navBar.classList.contains('expanded')) {
            this.insideTm = setTimeout(() => {
                if (!_self.navbarService.isMouseInRegion()) {
                    this._renderer.removeClass(navBar, 'expanded');
                    this._renderer.removeClass(mainMenu, 'expanded');
                    this.resetOpenMenu();
                    this.setOpenInNavbar(false);
                }
            }, 100);
        }
        this.navbarService.setMouseInRegion(false);
    }

    // // example to update badge value dynamically from another component
    // updateMenuBadgeValue() {
    //     for (let i = 0; i < this._menuSettingsConfig.items.length; i++) {
    //         if (this._menuSettingsConfig.items[i].badge) {
    //             this._menuSettingsConfig.items[i].badge.value = 19;
    //         }
    //     }
    //     this._menuSettingsService.config = this._menuSettingsConfig;
    // }
    //
    // handleCollapseOfMenu(element) {
    //     if (element.classList && element.classList.contains('has-sub') && element.classList.contains('open')) {
    //         element.classList.remove('open');
    //         element.classList.remove('hover');
    //         element.classList.add('menu-collapsed-open');
    //     }
    // }

    handleExpandOfMenu(element) {
        if (element.classList && element.classList.contains('has-sub') &&
            element.classList.contains('menu-collapsed-open')) {
            element.classList.remove('menu-collapsed-open');
            element.classList.add('open');
            element.classList.add('hover');
        }
    }

    // toggleMenu(event) {
    //     const target = event.target || event.srcElement || event.currentTarget;
    //     const parent = target.parentElement;
    //     if (parent && parent.classList.contains('has-sub')) {
    //         this.openSubMenuUsingParent(parent);
    //     } else {
    //         const parentOfParent = parent.parentElement;
    //         this.openSubMenuUsingParent(parentOfParent);
    //     }
    // }

    openSubMenuUsingParent(parent) {
        if (parent.classList && parent.classList.contains('has-sub') &&
            !parent.classList.contains('open')) {
            parent.classList.add('open');
        } else if (parent.classList && parent.classList.contains('has-sub') &&
            parent.classList.contains('open')) {
            parent.classList.remove('open');
        }
    }

    toggleFullScreen() {
        const toggleIcon = document.getElementsByClassName('ficon');

        if (toggleIcon.item(0).classList.contains('ft-maximize')) {
            this.openfullscreen();
            this._renderer.removeClass(toggleIcon.item(0), 'ft-maximize');
            this._renderer.addClass(toggleIcon.item(0), 'ft-minimize');
        } else if (toggleIcon.item(0).classList.contains('ft-minimize')) {
            this.closefullscreen();
            this._renderer.addClass(toggleIcon.item(0), 'ft-maximize');
            this._renderer.removeClass(toggleIcon.item(0), 'ft-minimize');
        }
    }

    openfullscreen() {
        // Trigger fullscreen
        // tslint:disable-next-line: no-shadowed-variable
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
            docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
            docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
            docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }
    }

    closefullscreen() {
        // tslint:disable-next-line: no-shadowed-variable
        const docWithBrowsersExitFunctions = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
            docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
            docWithBrowsersExitFunctions.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
            docWithBrowsersExitFunctions.msExitFullscreen();
        }

    }

    toggleFixMenu(e) {
        if (this.document.body.classList.contains('menu-expanded')) {
            // show the left aside menu
            this.navbarService.setFixedMenu(false);
            this.document.body.classList.remove('menu-expanded');
            this.document.body.classList.add('menu-collapsed');
            // Change switch icon
            this._themeSettingsConfig.menu = 'collapse';
        } else {
            this.navbarService.setFixedMenu(true);
            this.document.body.classList.remove('menu-collapsed');
            this.document.body.classList.add('menu-expanded');
            // Change switch icon
            this._themeSettingsConfig.menu = 'expand';
        }
        const navBar = this.document.getElementById('navbar-header');
        const mainMenu = this.document.getElementById('main-menu');
        this._renderer.addClass(navBar, 'expanded');
        this._renderer.addClass(mainMenu, 'expanded');
        setTimeout(() => {
            AppConstants.fireRefreshEventOnWindow();
        }, 300);
    }

    toggleNavigation(e) {
        const sidenav = document.getElementById('sidenav-overlay');
        const sidebarLeft = document.getElementById('sidebar-left') || document.getElementById('email-app-menu') ||
            document.getElementById('sidebar-todo');
        const contentOverlay = document.getElementById('content-overlay');

        if (this.document.body.classList.contains('menu-open') && (this.router.url === '/todos' || this.router.url === '/contacts' ||
            this.router.url === '/email' || this.router.url === '/chats' || this.router.url === '/chats/static-chat')) {
            this.document.body.classList.remove('menu-open');
            this._renderer.removeClass(sidenav, 'd-block');
            this._renderer.removeClass(contentOverlay, 'show');
            this.document.body.classList.add('menu-close');
            this._renderer.addClass(sidenav, 'd-none');
            this.showNavbar = false;
        } else if (this.document.body.classList.contains('menu-open')) {
            this.document.body.classList.remove('menu-open');
            this._renderer.removeClass(sidenav, 'd-block');
            this.document.body.classList.add('menu-close');
            this._renderer.addClass(sidenav, 'd-none');
            this.showNavbar = false;
        } else {
            this._renderer.removeClass(sidenav, 'd-none');
            this.document.body.classList.remove('menu-close');
            this.document.body.classList.add('menu-open');
            this._renderer.addClass(sidenav, 'd-block');
            this.showNavbar = false;
        }

        if (sidebarLeft) {
            this._renderer.removeClass(sidebarLeft, 'show');
        }
        if (contentOverlay) {
            this._renderer.removeClass(contentOverlay, 'show');
        }
    }

    toggleNavbar(e) {
        if (this.showNavbar) {
            this.showNavbar = false;
        } else {
            this.showNavbar = true;
        }
    }

    public clickSearch() {
        if (this.isHeaderSearchOpen) {
            this.isHeaderSearchOpen = false;
        } else {
            this.isHeaderSearchOpen = true;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {
            this.isMobile = true;
            this.showNavbar = false;
        } else {
            this.isMobile = false;
            this.showNavbar = true;
        }
    }

    /**
     * リフレッシュ処理
     */
    onRefresh(): void {
        location.reload();
    }

    /**
     * 自動リフレッシュ
     * @param value リフレッシュフラグ
     */
    onAutoRefresh(value): void {
        this.autoRefresh = value;
        if (value) {
            localStorage.setItem("AutoRefresh", value);
            this.startTimer();
        } else {
            localStorage.removeItem("AutoRefresh");
            this.stopTimer();
        }
    }

    /**
     * 前ページに戻る
     */
    onBack(): void {
        history.back();
    }

    /**
     * ヘルプダイアログを開く
     */
    onDownloadHelpFile(): void {
        this.helpModalService.open("heard_help_modal");
    }

    // /**
    //  * ヘルプダイアログを開く
    //  */
    // onDownloadHelpFile(): void {
    //     this.helpDoc = "assets/help/AAAS-Watch Console_ご利用クイックガイド.pdf";
    //     this.modalService.open("heard_help_modal");
    //
    //     // const modalContent = document.getElementsByClassName("modal-content");
    //     // let htmlContent = modalContent[0] as HTMLElement;
    //     // htmlContent.style.width = "830px";
    // }
    //
    // /**
    //  * ヘルプファイルを保存する
    //  */
    // onSaveClick(): void {
    //     let link = document.createElement("a");
    //     link.download = "AAAS-Watch Console_ご利用クイックガイド.pdf";
    //     link.href = "assets/help/AAAS-Watch Console_ご利用クイックガイド.pdf";
    //     link.click();
    // }

    /**
     * 自動リフレッシュのタイマー
     */
    private startTimer(): void {
        // 1000 * 60 * 10は１０分です
        this.timer = setInterval(function () {
            location.reload();
        }, 1000 * 60 * 10);
    }

    /**
     * タイマー終了
     */
    private stopTimer(): void {
        clearInterval(this.timer);
    }

    selectedRows: any;
    myMessageListModal = null;

    onSendMessageClick(modalContent): void {
        this.myMessageListModal = this.modalService1.open(modalContent, {
            windowClass: 'animated fadeInDown',
            size: 'lg',
            centered: true
        });
    }

    cancleModel() {
        if (this.myMessageListModal != null) {
            this.myMessageListModal.close();
        }
    }

    // private getProfileUserInfo(): void {
    //     this.httpService.useGet('user/current').then(result => {
    //         if (result != null) {
    //             console.log(result);
    //             localStorage.setItem("profileUserInfo", JSON.stringify(result.data));
    //
    //             this.username = result.data.name;
    //             // 該当なユーザー情報設定
    //             this.currentUser = result.data;
    //             // 個人画像
    //             if (this.currentUser.picture) {
    //                 this.currentUser.picture = JSON.parse(this.currentUser.picture);
    //             }
    //         }
    //     }).catch((e) => {
    //         console.log('ログインユーザー情報を検索API エラー　発生しました。');
    //     });
    // }


    private getProfileUserInfo(): void {
        this.userService.subcribeForCurrentUserProfileChanged().subscribe(profileUserInfo => {
            this.currentUser = profileUserInfo;
            this.username = this.currentUser.name;
            if (this.currentUser.picture) {
                this.avatarUrl = `${this.currentUser.picture.baseUrl}${this.currentUser.picture.origin}?t=${new Date().getTime()}`;
            }
        });
        this.userService.emitCurrentUserProfileRefreshEvent();
    }

    private getLocaleInfo(): void {
        this.localeInfo.localeList = this.selfUserInfoService.getLocaleList();
        this.locale = this.selfUserInfoService.getLocaleInfo();
    }

    /**
     * ロケールを変更する
     */
    onChangeLocale(event): void {
        this.localeInfo.localeList.forEach((locale) => {
            if (locale.iconClass === event.srcElement.className
                || getTransMessage(this.translate, locale.localeId) === event.srcElement.innerText) {

                // イベントありの場合
                this.clickLocale(this, locale);
            }
        });
    }

    private clickLocale(This: any, locale: any): void {
        const current = localStorage.getItem('CURRENT_LANG');
        if (current !== locale.localeId) {
            // 変更した言語設定
            localStorage.setItem('CURRENT_LANG', locale.localeId);
            this.setUserLang();
            // 言語変更通知
            this.language.setLanguage(locale.localeId);
        }
    }

    private setUserLang() {
        let localeInfo: any;
        const current = this.language.getCurrentLanguage();
        this.selfUserInfoService.getLocaleList().forEach((locale) => {
            if (locale.localeId === current) {
                localeInfo = locale;
            }
        });

        this.locale = localeInfo;
        this.selfUserInfoService.setLocaleInfo(localeInfo);
    }




}
