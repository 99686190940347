import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IfcsModalModule} from "ifocus-lib";
import {HelpModalComponent} from "./help-modal.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {HelpModalService} from "./help-modal.service";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [HelpModalComponent],
    imports: [CommonModule, IfcsModalModule, PdfViewerModule, TranslateModule],
    providers: [HelpModalService,],
    exports: [HelpModalComponent],
})
export class HelpModalModule {
}
