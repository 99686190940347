import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import { HttpService } from '../_services/HttpService';


@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  public pageModel = {
    email: '',
    password: '',
    confirmpassword: '',
    pwdDifferent: true
  }
  constructor(
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.pageModel.pwdDifferent = false;

    this.userService.getUsers().subscribe(users => {
      this.users = users.map(item => {
        return {
          ...item.payload.doc.data() as {},
          id: item.payload.doc.id
        };
      });
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  async tryRegister() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.submitted = false;
      return;
    }
    if (this.registerForm.value.password != this.registerForm.value.confirmpassword) {
      this.submitted = false;
      this.pageModel.pwdDifferent = true;
      this.alertService.error('パスワードとパスワード（確認用）に同じパスワードを設定してください');
      return;
    }
    this.loading = true;
    try {
      ///認証
      const value = {
        email: this.f.email.value,
        password: this.f.password.value
      };
      // Create user with email and pass.
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password).then((info) => {
        this.submitted = false;
        info.user
        this.alertService.success('登録成功しました。');

      }).catch((error) => {
        // Handle Errors here.
        this.submitted = false;
        this.alertService.error(error.message);
        console.log(error);
      });
    } catch (err) {
      this.submitted = false;
      // this.handleError('操作失敗', err);
      this.alertService.error(err.message);
    }


  }
}
