import {RouterModule, Routes} from '@angular/router';
import {PublicLayoutComponent} from './_layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './_layout/private-layout/private-layout.component';
import {AuthGuard} from './_guards/auth.guard';
import {RegisterComponent} from './register';
import {LoginComponent} from './login';
import {ChangelogComponent} from './changelog/changelog.component';
import {FullLayoutComponent} from './_layout/full-layout/full-layout.component';
import {PrivacyPolicyComponent} from './login/privacy-policy/privacy-policy.component';
import {TermsConditionComponent} from './login/terms-condition/terms-condition.component';


const appRoutes: Routes = [
    {path: 'privacypolicy', component: PrivacyPolicyComponent},
    {path: 'termCondition', component: TermsConditionComponent},
    // Public layout
    {
        path: '',
        component: PublicLayoutComponent,
        children: [
            // {path: 'register', component: RegisterComponent},
            // {path: 'login', component: LoginComponent},
            // {path: '', component: LoginComponent},
            {
                path: 'login',
                loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule),
                canActivate: [AuthGuard]
            },
            {
                path: '', loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule),
                canActivate: [AuthGuard]
            },

        ]
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: 'error',
                loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'authentication',
                loadChildren: () => import('../app/content/full-pages/authentication/authentication.module').then(m =>
                    m.AuthenticationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'others',
                loadChildren: () => import('../app/content/full-pages/others/others.module').then(m => m.OthersModule),
                canActivate: [AuthGuard]
            }
        ]
    },
    // Private layout
    {
        path: '',
        component: PrivateLayoutComponent,
        children: [
            {
                path: 'profile',
                loadChildren: () => import('../app/content/profile/profile.module').then(m => m.ProfileModule),
                canActivate: [AuthGuard]
            },

            {path: 'logout', component: LoginComponent, canActivate: [AuthGuard]},
            {path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard]},
            {
                path: 'dashboard',
                loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'home', loadChildren: () => import('../app/content/home/home.module').then(m => m.HomeModule)
                , canActivate: [AuthGuard]
            },
            {
                path: 'historyrecords',
                loadChildren: () => import('../app/content/historyrecords/historyrecords.module').then(m => m.HistoryrecordsModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'group', loadChildren: () => import('../app/content/group/group.module').then(m => m.GroupModule)
                , canActivate: [AuthGuard]
            },
            {
                path: 'product',
                loadChildren: () => import('../app/content/product/product.module').then(m => m.ProductModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'group-chat', loadChildren: () => import('../app/content/group-chat/group-chat.module').then(m => m.GroupChatModule)
                , canActivate: [AuthGuard]
            },
            {
                path: 'user', loadChildren: () => import('../app/content/user/user.module').then(m => m.UserModule)
                , canActivate: [AuthGuard]
            },
            {
                path: 'components',
                loadChildren: () => import('../app/content/ngbbootstrap/components.module').then(m => m.ComponentsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'todo-app',
                loadChildren: () => import('../app/content/applications/todo-app/todo-app.module').then(m => m.TodoAppModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'chats',
                loadChildren: () => import('../app/content/applications/chat/chats.module').then(m => m.ChatsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'email',
                loadChildren: () => import('../app/content/applications/email/email.module').then(m => m.EmailModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'calender',
                loadChildren: () => import('../app/content/applications/calender/calender.module').then(m => m.CalenderModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'notice', loadChildren: () => import('./content/notice/notice.module').then(m => m.NoticeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'alertList',
                loadChildren: () => import('../app/content/alertList/alertList.module').then(m => m.AlertListModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'chartjs',
                loadChildren: () => import('../app/content/charts-maps/chartjs/chartjs.module').then(m => m.ChartjsModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'form-elements',
                loadChildren: () => import('../app/content/forms/form-elements/form-elements.module').then(m =>
                    m.FormElementsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'form-layouts',
                loadChildren: () => import('../app/content/forms/form-layouts/form-layouts.module').then(m =>
                    m.FormLayoutsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'form-wizard',
                loadChildren: () => import('../app/content/forms/form-wizard/form-wizard.module').then(m =>
                    m.FormWizardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'form-repeater',
                loadChildren: () => import('../app/content/forms/form-repeater/form-repeater.module').then(m =>
                    m.FormRepeaterModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ngchartist',
                loadChildren: () => import('../app/content/charts-maps/ngchartist/ngchartist.module').then(m =>
                    m.NgchartistModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'boostraptables',
                loadChildren: () => import('../app/content/table/boostraptables/boostraptables.module').then(m =>
                    m.BoostraptablesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'datatables',
                loadChildren: () => import('../app/content/table/datatables/datatables.module').then(m => m.DatatablesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'datatablesext',
                loadChildren: () => import('../app/content/table/datatablesext/datatablesext.module').then(m =>
                    m.DatatablesextModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icons',
                loadChildren: () => import('../app/content/icons/icons.module').then(m => m.IconsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'cards',
                loadChildren: () => import('../app/content/cards/cards.module').then(m => m.CardsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'maps',
                loadChildren: () => import('../app/content/maps/maps.module').then(m => m.MapsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'invoice',
                loadChildren: () => import('../app/content/pages/invoice/invoice.module').then(m => m.InvoiceModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'timelines',
                loadChildren: () => import('../app/content/pages/timelines/timelines.module').then(m => m.TimelinesModule),
                canActivate: [AuthGuard]
            },

            {
                path: 'gallery',
                loadChildren: () => import('../app/content/pages/gallery/gallery.module').then(m => m.GalleryModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'news-feed',
                loadChildren: () => import('../app/content/pages/news-feed/news-feed.module').then(m => m.NewsFeedModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'dropzone',
                loadChildren: () => import('../app/content/pages/dropzone/dropzone.module').then(m => m.DropzoneModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'social-feed',
                loadChildren: () => import('../app/content/pages/social-feed/social-feed.module').then(m =>
                    m.SocialFeedModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'search',
                loadChildren: () => import('../app/content/pages/search/search.module').then(m => m.SearchModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'advanceCards',
                loadChildren: () => import('../app/content/advance-cards/advance-cards.module').then(m =>
                    m.AdvanceCardsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'extraComponents',
                loadChildren: () => import('../app/content/extra-components/extra-components.module').then(m =>
                    m.ExtraComponentsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'watch',
                loadChildren: () => import('../app/content/watch/watch.module').then(m => m.WatchModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'contact',
                loadChildren: () => import('../app/content/contact/contact.module').then(m => m.ContactModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'template',
                loadChildren: () => import('../app/content/template/template.module').then(m => m.TemplateModule),
                canActivate: [AuthGuard]
            },
            // { path: 'user', loadChildren: () => import('../app/content/pages/user/user.module').then(m => m.UserModule)
            // , canActivate: [AuthGuard] },
            // {
            //     path: 'notifications',
            //     loadChildren: () => import('../app/content/notifications/notifications.module').then(m => m.NotificationsModule),
            //     canActivate: [AuthGuard]
            // },
            {
                path: 'notification-settings',
                loadChildren: () => import('../app/content/notification-center/notification-center.module').then(m => m.NotificationCenterModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'watch-photos',
                loadChildren: () => import('../app/content/watch-photos/watch-photos.module').then(m => m.WatchPhotosModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'alert-notifications',
                loadChildren: () => import('../app/content/alert-notifications/alert-notifications.module').then(m => m.AlertNotificationsModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'watch-sign',
                loadChildren: () => import('../app/content/watch-sign/watch-sign.module').then(m => m.WatchSignModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'room-wifi',
                loadChildren: () => import('../app/content/room-wifi/room-wifi.module').then(m => m.RoomWifiModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'gate-pass',
                loadChildren: () => import('../app/content/gate-pass/gate-pass.module').then(m => m.GatePassModule)
                ,
                canActivate: [AuthGuard]
            },
            {
                path: 'channel-platform',
                loadChildren: () => import('../app/channel-platform/channel-platform.module').then(m => m.ChannelPlatformModule)
                ,canActivate: [AuthGuard]
            },
            
            // {
            //     path: 'boot-info',
            //     loadChildren: () => import('../app/content/bootstrap/device-boot-info/device-boot-info.module').then(m => m.DeviceBootInfoModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            {
                path: 'support',
                loadChildren: () => import('../app/content/support/support.module').then(m => m.SupportModule)
                ,
                canActivate: [AuthGuard]
            },
            // // 管理メニュー
            // // デバイス会社
            // {
            //     path: 't-device-company',
            //     loadChildren: () => import('../app/management/t-device-company/t-device-company.module').then(m => m.TDeviceCompanyModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            // // デバイスSIMカード
            // {
            //     path: 't-device-sim-card',
            //     loadChildren: () => import('../app/management/t-device-sim-card/t-device-sim-card.module').then(m => m.TDeviceSimCardModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            // // デバイス
            // {
            //     path: 'm-device',
            //     loadChildren: () => import('../app/management/m-device/m-device.module').then(m => m.MDeviceModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            // // 会社
            // {
            //     path: 'm-company',
            //     loadChildren: () => import('../app/management/m-company/m-company.module').then(m => m.MCompanyModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            // // SIMカード
            // {
            //     path: 'm-sim-card',
            //     loadChildren: () => import('../app/management/m-sim-card/m-sim-card.module').then(m => m.MSimCardModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },
            // // 注文
            // {
            //     path: 't-device-order',
            //     loadChildren: () => import('../app/management/t-device-order/t-device-order.module').then(m => m.TDeviceOrderModule)
            //     ,
            //     canActivate: [AuthGuard]
            // },

        ],
    },
    // otherwise redirect to home
    {path: '**', redirectTo: 'changelog'}
];

export const routing = RouterModule.forRoot(appRoutes, {
    useHash: true,
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
});
