import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class VerticalService {

  private updateAlertCall = new Subject<any>();
  public updateAlertCall$ = this.updateAlertCall.asObservable();
  private updateNoticeCall = new Subject<any>();
  public updateNoticeCall$ = this.updateNoticeCall.asObservable();

  public updateAlert(){
    this.updateAlertCall.next();
  }

  public updateNotice(){
    this.updateNoticeCall.next();
  }
}