<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
    <div class="navbar-wrapper">
        <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
             (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
            <ul class="nav navbar-nav flex-row">
                <!-- Remove position relative in responsive -->
                <li class="nav-item mobile-menu d-lg-none mr-auto"><a
                        class="nav-link nav-menu-main menu-toggle hidden-xs11"
                        (click)="toggleNavigation($event)">
                    <i class="feather ft-menu font-large-1"></i></a></li>
                <!-- dashboard-->
                <li class="nav-item mr-auto"><a [routerLink]="['/dashboard']" class="navbar-brand"
                                                routerLink="/dashboard/sales"><img class="brand-logo"
                                                                                   alt="modern admin logo"
                                                                                   [src]="_themeSettingsConfig.brand.logo.value">
                    <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
                </a></li>

                <!-- メニューバーを自動的に隠す-->
                <li class="nav-item d-none d-md-block nav-toggle" title="{{'automaticallyHideTheMenuBar' | translate}}">
                    <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
                       (click)="toggleFixMenu($event)">
                        <i class="feather toggle-icon font-medium-3 white"
                           [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}"></i>
                    </a>
                </li>
                <li class="nav-item d-lg-none">
                    <a class="nav-link open-navbar-container" data-toggle="collapse"
                       data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i
                            class="la la-ellipsis-v"></i></a>
                </li>
            </ul>
        </div>
        <!-- New-->
        <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <!-- 左側-->
                <ul class="nav navbar-nav mr-auto float-left">
                    <!-- フルスクリーン表示-->
                    <li class="nav-item d-none d-md-block" title="{{'fullScreenMode' | translate}}">
                        <a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()"
                           *ngIf="maximize === 'on'">
                            <i class="ficon feather ft-maximize"></i></a>
                    </li>

                    <!-- リフレッシュ-->
                    <li class="nav-item d-none d-md-block" title="{{'refresh' | translate}}">
                        <a [routerLink]="" class="nav-link nav-link-expand" (click)="onRefresh()"
                           *ngIf="maximize === 'on'">
                            <i class="ficon feather icon-refresh"></i></a>
                    </li>

                    <!-- １０分毎に自動的なリフレッシュ-->
                    <li class="nav-item d-none d-md-block" title="{{'automaticallyRefreshEvery10Minutes' | translate}}">
                        <a class="nav-link nav-link-expand">
                            <ui-switch size="small" [(ngModel)]="autoRefresh"
                                       (valueChange)="onAutoRefresh($event)"></ui-switch>
                        </a>
                    </li>

                    <!-- 戻る-->
                    <li class="nav-item d-none d-md-block" title="{{'goBackToThePreviousPage' | translate}}">
                        <a [routerLink]="" class="nav-link nav-link-expand" (click)="onBack()"
                           *ngIf="maximize === 'on'">
                            <i class="ficon la la-mail-reply"></i></a>
                    </li>

                </ul>
                <!-- 右側-->
                <ul class="nav navbar-nav float-right">

                    <!-- ロケール-->
                    <li class="dropdown-language nav-item" ngbDropdown>
                        <a class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
                            <i style="border: 1px solid #d0d0d0;" [ngClass]="locale?.iconClass"></i>
                            <span class="selected-language"></span>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdown-flag"
                             *ngIf="localeInfo !== null && localeInfo?.localeList?.length > 0">
                            <a class="dropdown-item"
                               *ngFor="let flg of localeInfo.localeList; index as idx"
                               (click)="onChangeLocale($event)">
                                <i [ngClass]="flg.iconClass"></i>
<!--                                <span translate>{{flg.localeId}}</span>-->
                                <span>{{flg.localeName}}</span>
                            </a>
                        </div>
                    </li>

                    <!-- アラート-->
                    <li class="dropdown-notification nav-item dropdown" ngbDropdown>
                        <a class="nav-link nav-link-label" ngbDropdownToggle [routerLink]="['/alert-notifications']"
                           (click)="ngOnInit()" *ngIf="alertAnread === 0">
                            <i class="ficon feather ft-alert-triangle" title="{{'alert' | translate}}"></i>
                        </a>

                        <ng-container *ngIf="alertAnread !== 0">
                            <a class="nav-link nav-link-label" ngbDropdownToggle>
                                <i class="ficon feather ft-alert-triangle"></i>
                                <span class="badge badge-pill badge-danger badge-up badge-glow">{{alertAnread}}</span>
                            </a>
                            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                                <li class="dropdown-menu-header">
                                    <h6 class="dropdown-header m-0"><span class="grey darken-2">{{'alert' | translate}}</span></h6>
                                    <span class="notification-tag badge badge-default badge-danger float-right m-0">
                                        {{alertAnread}} {{'barNew' | translate}}</span>
                                </li>
                                <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                                    fxFlex="auto" [perfectScrollbar]="config">
                                    <a href="javascript:void(0)" *ngFor="let item of alertUnreadList; index as idx">
                                        <div class="media">
                                            <div class="media-left align-self-center">
                                                <i class="feather ft-alert-triangle icon-bg-circle bg-red bg-darken-1"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="media-heading red darken-1">{{item.sourceUserName}}</h6>
                                                <p class="notification-text font-small-3 text-muted">{{item.detail}}</p>
                                                <small>
                                                    <time class="media-meta text-muted"
                                                          datetime="2015-06-11T18:29:20+08:00">{{item.createdTimestamp}}
                                                    </time>
                                                </small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="dropdown-menu-footer">
                                    <a class="dropdown-item text-muted text-center" href="javascript:void(0)"
                                       [routerLink]="['/alert-notifications']"><span class="alert_span">{{'readAllAlerts' | translate}}</span></a>
                                </li>
                            </ul>
                        </ng-container>
                    </li>

                    <!-- お知らせ-->
                    <li class="dropdown-notification nav-item dropdown" ngbDropdown *ngIf="notification === 'on'">
                        <a class="nav-link nav-link-label" ngbDropdownToggle [routerLink]="['/alert-notifications']"
                           [queryParams]="{type: 'notice'}" (click)="ngOnInit()" *ngIf="noticeAnread === 0">
                            <i class="ficon feather ft-bell" title="{{'notification' | translate}}"></i>
                        </a>
                        <ng-container *ngIf="noticeAnread !== 0">
                            <a class="nav-link nav-link-label" ngbDropdownToggle>
                                <i class="ficon feather ft-bell"></i>
                                <span class="badge badge-pill badge-warning badge-up badge-glow">{{noticeAnread}}</span>
                            </a>
                            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                                <li class="dropdown-menu-header">
                                    <h6 class="dropdown-header m-0"><span class="grey darken-2">{{'announcement' | translate}}</span>
                                    </h6>
                                    <span class="notification-tag badge badge-default badge-warning float-right m-0">
                                        {{noticeAnread}} {{'barNew' | translate}}</span>
                                </li>
                                <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                                    fxFlex="auto" [perfectScrollbar]="config">
                                    <a href="javascript:void(0)" *ngFor="let item of noticeUnreadList; index as idx">
                                        <div class="media">
                                            <div class="media-left align-self-center"><i
                                                    class="feather ft-bell icon-bg-circle bg-yellow bg-darken-3"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="media-heading yellow darken-3">{{item.notificationType}}</h6>
                                                <p class="notification-text font-small-3 text-muted">{{item.detail}}</p>
                                                <small>
                                                    <time class="media-meta text-muted"
                                                          datetime="2015-06-11T18:29:20+08:00">{{item.createdTimestamp}}
                                                    </time>
                                                </small>
                                            </div>
                                        </div>
                                    </a>

                                </li>
                                <li class="dropdown-menu-footer">
                                    <a class="dropdown-item text-muted text-center" href="javascript:void(0)"
                                       [routerLink]="['/alert-notifications']" [queryParams]="{type: 'notice'}">
                                        <span class="notification_span">{{'readAllNotifications' | translate}}</span>
                                    </a>
                                </li>
                            </ul>
                        </ng-container>
                    </li>

                    <!-- メッセージ送信-->
                    <li class="dropdown-notification nav-item dropdown" ngbDropdown>
                        <a class="nav-link nav-link-label" ngbDropdownToggle
                           (click)="onSendMessageClick(myMessageModal)">
                            <i class="ficon la la-bullhorn" title="{{'sendMessage' | translate}}"></i>
                        </a>
                    </li>

                    <!--                    &lt;!&ndash; ヘルプ&ndash;&gt;-->
                    <!--                    <li class="dropdown-notification nav-item dropdown" ngbDropdown>-->
                    <!--                        <a class="nav-link nav-link-label" ngbDropdownToggle (click)="onDownloadHelpFile()">-->
                    <!--                            <i class="ficon feather ft-help-circle" title="ヘルプ"></i>-->
                    <!--                        </a>-->
                    <!--                    </li>-->

                    <!-- プロファイル管理-->
                    <li class="dropdown-user nav-item" ngbDropdown>
                        <a class="nav-link dropdown-user-link" ngbDropdownToggle>
                            <span *ngIf="currentUser && currentUser.displayName"
                                  class="mr-1 user-name text-bold-700">{{currentUser.displayName}}</span>
                            <span *ngIf="!currentUser || !currentUser.displayName"
                                  class="mr-1 user-name text-bold-700">{{username}}</span>
                            <span class="avatar avatar-online">
                                <img *ngIf="currentUser && currentUser.photoURL" src="{{currentUser.photoURL}}" class="imgWH"
                                     alt="avatar">
                                <img *ngIf="!currentUser || !currentUser.photoURL" [src]="avatarUrl" class="imgWH" alt="avatar">
                                <!--                                <img *ngIf="!currentUser.photoURL && currentUser.picture"-->
                                <!--                                     src="{{currentUser.picture.baseUrl}}{{currentUser.picture.origin}}" alt="avatar">-->
                                <!--                                <img *ngIf="!currentUser.photoURL && !currentUser.picture"-->
                                <!--                                     src="../../../assets/images/portrait/small/default.png" alt="avatar">-->
                                <i></i>
                            </span>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                             aria-labelledby="dropdownProfileMenu">
                            <a class="dropdown-item" [routerLink]="['/profile']">
                                <i class="feather ft-user"></i>{{'profileManagement' | translate}}</a>
                            <a class="dropdown-item" (click)="onDownloadHelpFile()"><i
                                    class="feather ft-help-circle"></i>{{'help' | translate}}</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [routerLink]="" (click)="logout()">
                                <i class="feather ft-power"></i>{{'logOut' | translate}}</a>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
        <!-- New-->
    </div>
</nav>


<p-toast position="top-right" key="alertModal" baseZIndex="999"></p-toast>

<!-- MY送信メッセージ一覧 -->
<ng-template #myMessageModal let-c="close" let-d="dismiss">
    <app-device-message-my [selectedRows]="selectedRows" [deviceSelDlg]="true" (closeEvent)="cancleModel()">
    </app-device-message-my>
</ng-template>


<!-- ヘルプダイアログ-->
<app-help-modal helpId="heard_help_modal" helpIdpro="heard_help_modal_pro" helpIdNotpro="heard_help_modal_Notpro" helpIdDevice="heard_help_modal_Device"></app-help-modal>

<!--<ifcs-modal modalId="heard_help_modal"-->
<!--            title="ヘルプ" bodyClass="class-body" footerClass="class-footer"-->
<!--            cancelBtnText="閉じる" okBtnText="保存"-->
<!--            (okClick)="onSaveClick()">-->
<!--    <div class="modalBody">-->
<!--        <pdf-viewer [src]="helpDoc"-->
<!--                    [render-text]="true"-->
<!--                    style="display: block;">-->
<!--        </pdf-viewer>-->
<!--    </div>-->
<!--</ifcs-modal>-->
