import {
    DateUtilService,
    ObjectUtilService,
    StringConstant,
    StringUtilService
} from "ifocus-lib";


export class BusinessUtils {

    /**
     * タイムゾーン（日本）
     * Asia/Tokyo
     */
    private static TIMEZONE_JAPAN: string = 'Asia/Tokyo';


    /**
     * 全角文字列存在チェック
     * @param str 指定した文字列
     */
    public static isFullWidthExists(str: string): RegExpMatchArray | null {
        return StringUtilService.matchString(str, StringConstant.regexp_full_width_exists)
    }

    /**
     * ログイン情報取得
     */
    public static getProfileUserInfo(): any {
        // ログインユーザー取得
        let profileUserInfo = localStorage.getItem("profileUserInfo");
        if (!profileUserInfo) {
            return null;
        }
        // 該当なユーザー情報設定
        return JSON.parse(profileUserInfo);
    }

    /**
     * ログインのロケール情報取得
     */
    public static getUserLocaleInfo(): any {
        const profileUserInfo: any = BusinessUtils.getProfileUserInfo();
        return (!!profileUserInfo) ? profileUserInfo.locale : null;
    }

    /**
     * タイムゾーン変換、結果は「yyyy-MM-dd HH:mm:ss」の形をデフォルトとして返却する（日本→指定したタイムゾーン）
     * @param date 元データ
     * @param format フォーマット
     */
    public static convertDateTimeForTimezone(date: Date | string | number, format?: string): string {
        // const MyTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log(MyTimeZone);
        // タイムゾーン取得
        const timezone = BusinessUtils.getUserTimezone();
        // console.log(timezone);
        const sourceTimeZone: string = BusinessUtils.TIMEZONE_JAPAN;
        const targetTimeZone: string = timezone ? timezone : BusinessUtils.TIMEZONE_JAPAN;

        const ft = ObjectUtilService.isNullOrUndefined(format) ? StringConstant.format_yyyyMMddHHmmss_hyphen : format;
        const targetDate = BusinessUtils.convertDateByTimezone(date, sourceTimeZone, targetTimeZone);
        return DateUtilService.formatDate(targetDate, ft);
    }

    /**
     * タイムゾーン変換、結果は「yyyy-MM-dd HH:mm:ss」の形をデフォルトとして返却する（指定したタイムゾーン→日本）
     * @param date 元データ
     * @param format フォーマット
     */
    public static convertDateTimeForJpTimezone(date: Date | string | number, format?: string): string {
        // タイムゾーン取得
        const timezone = BusinessUtils.getUserTimezone();
        // console.log(timezone);
        const sourceTimeZone: string = timezone ? timezone : BusinessUtils.TIMEZONE_JAPAN;
        const targetTimeZone: string = BusinessUtils.TIMEZONE_JAPAN;

        const ft = ObjectUtilService.isNullOrUndefined(format) ? StringConstant.format_yyyyMMddHHmmss_hyphen : format;
        const targetDate = BusinessUtils.convertDateByTimezone(date, sourceTimeZone, targetTimeZone);
        return DateUtilService.formatDate(targetDate, ft);
    }

    /**
     * タイムゾーン変換
     * @param date 元データ
     * @param sourceTimeZone 元
     * @param targetTimeZone 先
     */
    private static convertDateByTimezone(date: Date | string | number,
                                         sourceTimeZone: string,
                                         targetTimeZone: string): Date | string | number {
        if (sourceTimeZone === targetTimeZone) {
            return date;
        }
        const newDate: Date | number = DateUtilService.convertTypeForVer2(date);
        return DateUtilService.convertDateByTimezone(newDate, sourceTimeZone, targetTimeZone);
    }

    /**
     * ログインユーザーのタイムゾーン情報取得
     */
    private static getUserTimezone(): string {
        const locale: any = BusinessUtils.getUserLocaleInfo();
        return locale ? locale.timezone : null;
    }


}
