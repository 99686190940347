import {AfterViewChecked, Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {IfcsComponentBase, IfcsModalService, LanguageService, OnIfcsService} from "ifocus-lib";
import {Subscription} from "rxjs";
import {HelpModalService} from "./help-modal.service";
import {TranslateService} from "@ngx-translate/core";
import {getTransMessage} from "../../../utils/CommonFunction";


@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.css']
})
export class HelpModalComponent extends IfcsComponentBase implements OnInit, OnDestroy, OnIfcsService, AfterViewChecked {

    @Input() helpId: string;
    @Input() helpIdpro: string;
    @Input() helpIdNotpro: string;
    @Input() helpIdDevice: string;
    helpDoc: string;
    private subscriptions: Array<Subscription> = [];

    constructor(
        private modalService: IfcsModalService,
        private service: HelpModalService,
        private renderer: Renderer2,
        protected language: LanguageService,
        protected translate: TranslateService
    ) {
        // 多言語対応
        super(language, translate);
    }

    ngOnInit(): void {
        // subscribe設定
        this.doSubscribe();
    }

    protected notice(): void {
        // table 再読み取る
    }

    ngAfterViewChecked(): void {
    }

    doSubscribe(): void {
        this.subscriptions.push(this.service.showCalled$.subscribe(
            (id: string) => {
                this.showSelectHelpFile(id);
            }
        ));
    }

    /**
     * subscribe解除
     */
    doUnsubscribe(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    ngOnDestroy(): void {
        // subscribe解除
        this.doUnsubscribe();
    }

    showSelectHelpFile(id: string): void {
        if (this.helpId !== id) {
            return;
        }
        this.modalService.open(this.helpId);

        setTimeout(function (This) {
            console.log(This.helpId);
            const modalContent = document.getElementById(This.helpId);
            if (modalContent) {
                This.renderer.addClass(modalContent.parentNode, 'modal-content800');
            }
        }, 100, this);
    }

    /**
     * ヘルプダイアログを開く
     */
    showHelpFile(id: string): void {
        this.modalService.close(this.helpId);
        switch (id) {
            case 'pro':
                this.helpDoc = "assets/help/AAASWatch_Pro_Web_Console_User_Manual.pdf";
                this.modalService.open(this.helpIdpro);
                break;
            case 'notpro':
                this.helpDoc = "assets/help/AAAS-Watch_Web_Console_User_Manual.pdf";
                this.modalService.open(this.helpIdNotpro);
                break;
            case 'device':
                this.helpDoc = "assets/help/AAASWatchPro_User_Device_Manual.pdf";
                this.modalService.open(this.helpIdDevice);
                break;
        }


        // setTimeout(function (This) {
        //     console.log(This.showHelp);
        //     const modalContent = document.getElementById(This.showHelp);
        //     if (modalContent) {
        //         This.renderer.addClass(modalContent.parentNode, 'modal-content800');
        //     }
        // }, 100, this);

        // const modalContent = document.getElementsByClassName("modal-content");
        // let htmlContent = modalContent[0] as HTMLElement;
        // htmlContent.style.width = "830px";
    }

    /**
     * ヘルプファイルを保存する
     */
    onSaveClickpro(): void {
        let link = document.createElement("a");
        link.download = getTransMessage(this.translate, 'watchProManual') + ".pdf";
        link.href = "assets/help/AAASWatch_Pro_Web_Console_User_Manual.pdf";
        link.click();
    }

    onSaveClickNotpro(): void {
        let link = document.createElement("a");
        link.download = getTransMessage(this.translate, 'watchManual') + ".pdf";
        link.href = "assets/help/AAAS-Watch_Web_Console_User_Manual.pdf";
        link.click();
    }

    onSaveClickDevice(): void {
        let link = document.createElement("a");
        link.download = getTransMessage(this.translate, 'deviceManual') + ".pdf";
        link.href = "assets/help/AAASWatchPro_User_Device_Manual.pdf";
        link.click();
    }

    onCancelClickpro(): void {
        const modalContent = document.getElementById(this.helpIdpro);
        if (modalContent) {
            this.renderer.removeClass(modalContent.parentNode, 'modal-content800');
        }
    }

    onCancelClickNotpro(): void {
        const modalContent = document.getElementById(this.helpIdNotpro);
        if (modalContent) {
            this.renderer.removeClass(modalContent.parentNode, 'modal-content800');
        }
    }

    onCancelClickDevice(): void {
        const modalContent = document.getElementById(this.helpIdDevice);
        if (modalContent) {
            this.renderer.removeClass(modalContent.parentNode, 'modal-content800');
        }
    }

    onSelectCancelClick(): void {

        const modalContent = document.getElementById(this.helpId);
        if (modalContent) {
            this.renderer.removeClass(modalContent.parentNode, 'modal-content400');
        }
    }

}
