import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../_services/auth.service';
import {AlertService} from '../_services/alert.service';
import {HttpService} from '../_services/HttpService';
import {UserService} from '../_services/UserService';
import {CookieService} from 'ngx-cookie-service';
import {ConstantsHandler} from '../_common/_constant/constants.handler';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import {CommonConfirmDialogService} from '../_services/common.confirm.dialog.service';
import {WebNotificationMessageService} from '../_services/web-notification-message.service';
import {IfcsComponentBase, LanguageService, UtilFuncService} from "ifocus-lib";
import {TranslateService} from "@ngx-translate/core";
import {getDefaultLanguage, getTransMessage} from "../utils/CommonFunction";
import {SelfUserInfoService} from "../utils/SelfUserInfoService";
import { log } from 'console';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends IfcsComponentBase implements OnInit {
    @ViewChild('recaptchaWrapper', { static: true }) recaptchaWrapperRef!: ElementRef;
    /** 描画用キャンバス */
    @ViewChild('myCanvas') protected canvas: ElementRef;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    isPageLoaded = false;
    checkcodeList: string;
    uid: string;

    localeList = [];
    selectedLocaleId: string;

    countdown: number = 0;
    countdownInterval: any;

    profileForm: FormGroup;
    phoneSubmitted = false;
    // 認証コードフラグ
    sendcode = false;
    // 認証Id
    verificationId: string;
    // ログインユーザの電話番号
    phoneNumber: string;
    // signInToken
    signInToken:any;
    // 認証コード
    phoneCode:any;
    // ログインタイプ
    loginType = "email";
    appVerifier!: firebase.auth.RecaptchaVerifier;
    isCodeExpired: boolean;

    /** 2FA  Email*/
    email: string = '';
    verificationCode: string = '';
    /** 2FA */

    constructor(
        private httpService: HttpService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        public authService: AuthService,
        private renderer: Renderer2,
        private cookieService: CookieService,
        private confirmationService: CommonConfirmDialogService,
        private utilFuncService: UtilFuncService,
        private webNotificationMessageService: WebNotificationMessageService,
        private selfUserInfoService: SelfUserInfoService,
        protected language: LanguageService,
        protected translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        // 多言語対応
        super(language, translate);

        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });

    }

    ngOnInit() {
        super.ngOnInit();

        // this.firebaseInit();
        this.init();
        this.isPageLoaded = true;
        localStorage.clear();

        this.setLocaleList();
        this.selectedLocaleId = getDefaultLanguage(this.translate);
    }

    //チェックコード
    ngAfterViewInit() {
        this.selectedLocaleId = getDefaultLanguage(this.translate);
        this.clickLocale();

        this.checkCodeDraw();
    }

    init() {
        let SuccessCookie: string = this.cookieService.get(ConstantsHandler.SUCCESS_INFO);
        if (SuccessCookie !== null && SuccessCookie !== '') {
            let SuccessInfo = JSON.parse(SuccessCookie);
            this.alertService.success(SuccessInfo);
        }
        this.cookieService.delete(ConstantsHandler.SUCCESS_INFO);
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            checkcode: ['', Validators.required]
        });
        this.getCheckCode();
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onChangeSelect(event) {
        this.selectedLocaleId = event.target.value;
        this.clickLocale();
    }


    tryLogin() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.submitted = false;
            return;
        }

        if (this.f.checkcode.value !== this.checkcodeList) {
            this.submitted = false;
            this.alertService.error(getTransMessage(this.translate, "confirmationCodeIsDifferent"));
            this.resetCheckCode();
            return;
        }

        let value = {
            email: UtilFuncService.zeroWidthTrim(this.f.email.value.trim()),
            password: this.f.password.value,
            refreshToken: '',
            token: '',
        };

        firebase.auth().signInWithEmailAndPassword(value.email, value.password).then((res) => {
        });


        // ログイン
        this.postLogin(value).then(async (result) => {
            // 通信失敗の場合
            if (!result.result) {
                console.log(result.errorMessage);
                this.resetCheckCode();
                this.submitted = false;
                this.alertService.error(getTransMessage(this.translate, "loginFailed"));
                return;
            }
            const signInToken = result.data;
            let user = signInToken.firebaseUser;

            
            // 本登録済の場合
            if (user.emailVerified) {
                
                // ログイン処理
                await this.doLogin(signInToken, value.email);

                // ロケールリスト設定
                await this.setLocaleInfo();

                // タイムゾーン
                this.getTimezoneList();

                // 個人情報設定
                await this.httpService.getProfileUserInfo();
                // sms認証
                // console.log(JSON.parse(localStorage.getItem('profileUserInfo')));
                if(JSON.parse(localStorage.getItem('profileUserInfo')).verificationType === "sms") {
                    // cookieの有効期限で認証の必要があるかどうかの判定
                    const userInfo = JSON.parse(localStorage.getItem('profileUserInfo'));
                    let successCookie: string = this.cookieService.get(`${ConstantsHandler.SMS_EXPIRE_TOKEN.id}_${userInfo.id}`);

                    if (!successCookie || successCookie !== userInfo.phoneNumber) {
                        this.loginType ="sms"
                        this.phoneNumber = JSON.parse(localStorage.getItem('profileUserInfo')).phoneNumber;
                        this.signInToken = signInToken;
                        this.submitted = false;
                        this.cookieService.delete(ConstantsHandler.GLOBAL_TOKEN.id);
                        this.onSendCode();
                       return
                    }
                }

                /// 2FA メール認証 Start
                if (JSON.parse(localStorage.getItem('profileUserInfo')).verificationType === "email") {
                    // cookieの有効期限で認証の必要があるかどうかの判定
                    const userInfo = JSON.parse(localStorage.getItem('profileUserInfo'));
                    let successCookie: string = this.cookieService.get(`${ConstantsHandler.EMAIL_EXPIRE_TOKEN.id}_${userInfo.id}`);
                    if (!successCookie || successCookie !== value.email) {
                        this.email = value.email;
                        this.loginType ="2faEmail";
                        this.signInToken = signInToken;
                        this.submitted = false;
                        this.cookieService.delete(ConstantsHandler.GLOBAL_TOKEN.id);
                        return;
                    }
                }
                /// 2FA メール認証 End

                // バージョンアップなしの場合
                if (!signInToken.versionConfirm) {
                    this.router.navigate(['/dashboard']);
                    return;
                }
                // バージョンアップありの場合
                this.confirmVersion().then(url => {
                    this.router.navigate([url]);
                    return;
                });

                return;
            }
            value.token = signInToken.token;

            // 仮登録の場合
            // メール認証
            this.confirmationService.confirm({
                message: getTransMessage(this.translate, "emailAddressVerificationIsRequired") + getTransMessage(this.translate, "pleasePressTheConfirmButtonForVerification"),
                header: getTransMessage(this.translate, "emailVerification"),
                accept: () => {
                    this.doHttpRequest("sendEmail", value).then((result) => {
                        this.showAlert("info", getTransMessage(this.translate, "verificationEmailHasBeenSent"));
                    }).catch((err) => {
                        console.error(err);
                    });
                },
                reject: () => {
                },
            });
            this.submitted = false;
            this.logout();
        }).catch((err) => {
            console.log(err);
            this.resetCheckCode();
            this.submitted = false;
            this.alertService.error(getTransMessage(this.translate, "loginFailed"));
        });

    }

    /**
     * 検証メールを再送する
     * @param event イベント
     */
    resendMail(event) {
        if (!this.f.email.value || !this.f.password.value) {
            this.showAlert("error", getTransMessage(this.translate, "userAndPasswordInput"));
            return;
        }

        let value = {
            email: this.f.email.value,
            password: this.f.password.value,
            token: '',
        };

        this.doHttpRequest("loginUser", value).then((result) => {
            let user = result.data.firebaseUser;
            // 検証済の場合
            if (user.emailVerified) {
                this.showAlert("info", getTransMessage(this.translate, "youWillBecomeAVerifiedUser"));
                return;
            }
            // 仮登録の場合
            value.token = result.data.token;
            // メール認証
            this.doHttpRequest("sendEmail", value).then((result) => {
                this.showAlert("info", getTransMessage(this.translate, "verificationEmailHasBeenSent"));
            }).catch((err) => {
                this.showAlert("error", getTransMessage(this.translate, "sendingFailedPleaseTryAgainLater"));
                console.error(err);
            });
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.submitted = false;
            this.logout();
        });

    }

    /**
     * パスワードをリセットする
     * @param event イベント
     */
    restPassword(event) {
        const value = {
            email: this.f.email.value,
        };
        if (!value.email) {
            this.showAlert("error", getTransMessage(this.translate, "pleaseEnterAUsername"));
            return;
        }
        this.doHttpRequest("restPassword", value).then((result) => {
            this.showAlert("info", getTransMessage(this.translate, "pleaseConfirmThatThePasswordResetEmailHasBeenSent"));
        }).catch((err) => {
            console.log(err);
            this.alertService.error(getTransMessage(this.translate, "failedToSendThePasswordResetEmail"));
        });
    }

    /**
     * ログイン処理（ログイン成功後、画面遷移を行う）
     * @param data ユーザー
     * @param email メールアドレス
     */
    private async doLogin(data, email: string): Promise<void> {
        if (!data.token) {
            return;
        }
        ConstantsHandler.TOKEN.access_token = data.token;
        this.httpService.processLoginInfo(data);
        this.setLoginInStorage(data.token, email);

        this.webNotificationMessageService.register().then().catch(error => console.log(error));
    }

    /**
     * ログイン処理（ログイン成功後、画面遷移を行う）子コンポーネント用
     * @param data ユーザー&メールアドレス 
     */
    private async doLoginOutput(data): Promise<void> {
        if (!data.tokenInfo.token) {
            return;
        }
        ConstantsHandler.TOKEN.access_token = data.tokenInfo.token;
        this.httpService.processLoginInfo(data.tokenInfo);
        this.setLoginInStorage(data.tokenInfo.token, data.email);
        this.webNotificationMessageService.register().then().catch(error => console.log(error));
    }

    /**
     * ログアウト処理
     */
    private logout(): void {
        this.authService.doLogout().then(res => {
            this.router.navigate(['/']);
        }, err => {
            console.log(err);
        });
    }

    /**
     * メッセージ表示
     * @param alertType 種類
     * @param alertDetail メッセージ内容
     */
    private showAlert(alertType, alertDetail): void {
        this.showAlertSetlife(alertType, alertDetail, null);
    }

    /**
     * メッセージ表示
     * @param alertType 種類
     * @param alertDetail メッセージ内容
     * @param plife
     */
    private showAlertSetlife(alertType, alertDetail, plife): void {
        let lifeValue = 2000;
        if (plife !== null || plife !== '') {
            lifeValue = plife;
        }
        // let summary: string = alertType === "error" ? "エラー" : (alertType === "info" ? "情報" : "");
        this.utilFuncService.showAlert(alertType, alertDetail);
    }

    setUserInStorage(res) {
        if (res.user) {
            localStorage.setItem('currentUser', JSON.stringify(res.user));
        } else {
            localStorage.setItem('currentUser', res);
        }
    }

    setLoginInStorage(res, email) {
        localStorage.setItem('currentUser', JSON.stringify(email));
        localStorage.setItem('token', JSON.stringify(res));
    }

    /**
     * チェックコード作成
     */
    private checkCodeDraw(): void {
        ///チェックコード生成
        this.getCheckCode();
        ///キャンバスに描画
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        let ctx = canvasEl.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
            ctx.fillStyle = '#FF0000';
            ctx.font = "80px Arial, meiryo, sans-serif";
            ctx.textAlign = 'center';
            ctx.fillStyle = '#ffffff';
            ctx.fillText(this.checkcodeList, canvasEl.width / 2, 105, canvasEl.width);
        }
    }

    /**
     * チェックコード取得
     */
    private getCheckCode(): void {
        this.checkcodeList = '';
        [1, 2, 3, 4].forEach(abc => {
            this.checkcodeList += Math.floor(Math.random() * 10).toString();
        });
    }

    /**
     * チェックコード作成
     */
    private resetCheckCode(): void {
        this.checkCodeDraw();
    }

    /**
     * バージョン情報確認
     */
    private confirmVersion(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.confirmationService.confirm({
                message: getTransMessage(this.translate, "theLatestVersionHasBeenUpdatedWouldYouLikeToCheckOutTheNewFeatures") + getTransMessage(this.translate, "ifYouWantToCheckPleasePressTheConfirmButton"),
                header: getTransMessage(this.translate, "versionInfo"),
                accept: () => {
                    localStorage.setItem('versionConfirm', 'T');
                    return resolve('/profile');
                },
                reject: () => {
                    return resolve('/dashboard');
                },
            });
        });
    }

    /**
     * ログイン通信
     * @param param ゆーざー情報
     */
    private postLogin(param: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpService.post('login', param).then(result => {
                resolve(result);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    private doHttpRequest(url: string, param: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpService.post(url, param).then(result => {
                resolve(result);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    setLocaleList() {
        // await this.translate.get("en").toPromise();
        this.localeList = [];

        ConstantsHandler.multiLanguage.supportLangs.forEach((locale) => {
            this.localeList.push({
                localeId: locale,
                localeName: getTransMessage(this.translate, locale),
            });
        });
    }

    private clickLocale(): void {
        let current = localStorage.getItem("CURRENT_LANG");
        // console.log("clickLocale", current, this.selectedLocaleId);
        if (current !== this.selectedLocaleId) {
            // 変更した言語設定
            localStorage.setItem("CURRENT_LANG", this.selectedLocaleId);
            // 言語変更
            this.language.setLanguage(this.selectedLocaleId);
        }
    }

    private async getLocaleList() {
        return await this.httpService.useGet('locale/list').catch((e) => {
            console.log(e);
        });
    }

    private async setLocaleInfo() {
        // ロケールリスト設定
        const localeList = await this.getLocaleList();
        this.selfUserInfoService.setLocaleList(localeList);
        // 多言語のディフォルト設定
        const current = this.selectedLocaleId;
        localStorage.setItem("CURRENT_LANG", current);
        // ロケール設定
        let localeInfo: any = null;
        localeList.forEach((locale) => {
            if (locale.localeId === current) {
                localeInfo = locale;
            }
        });
        this.selfUserInfoService.setLocaleInfo(localeInfo);
    }

    private getTimezoneList() {
        this.httpService.useGet('tz/list').then(result => {
            this.selfUserInfoService.setTimezoneList(result);
            // console.log(this.selfUserInfoService.getTimezoneList();
        }).catch((e) => {
            console.log(e);
        });
    }

  setupRecaptcha() {
    if (this.appVerifier) {
        this.appVerifier.clear();
    }

    this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function(response) {
            console.log('reCAPTCHA solved',response);
        }
    });

    this.appVerifier.render().then(function(widgetId) {
       console.log(widgetId);
       
    });
  }

  initializeRecaptcha(): void {
    console.log("recaptchaWrapperRef",this.recaptchaWrapperRef);
    
    if (this.appVerifier) {
      this.appVerifier.clear();
      this.appVerifier = undefined
    }
    console.log(this.appVerifier);
    
    // clear reCAPTCHA 容器
    const child = document.getElementById('recaptcha-container-c');
    if(child){
    document.getElementById('recaptcha-container').removeChild(child);
    }

    let divId = "recaptcha-container"+new Date().getTime();
    document.getElementById('recaptcha-container').innerHTML = '<div id="'+divId+'"></div>';

    // 初期化 reCAPTCHA
    this.appVerifier = new firebase.auth.RecaptchaVerifier(divId, {
      size: 'invisible',
      callback: (response: any) => {
        console.log('reCAPTCHA solved');
      }
    });

    this.appVerifier.render();
    console.log(this.appVerifier);
  }

     /**
     * 認証コード発送イベント
     * @returns なし
     */
    async onSendCode(): Promise<void> {
    // 認証コード発送中の場合
    if (this.sendcode) {
        return;
    }
    this.sendcode = true;

    // // フォームが無効な場合
    // if (this.f.newphone.invalid) {
    //   this.sendcode = false;  // 送信フラグをリセットする
    //   return;
    // }
    // 電話番号
    const phoneNumber = this.phoneNumber;

    this.initializeRecaptcha();
    // new firebase.auth.RecaptchaVerifier('code', { size: 'invisible' }).clear();
    // let appVerifier = new firebase.auth.RecaptchaVerifier('code', { size: 'invisible' });

    // 電話認証プロバイダー取得
    const provider = new firebase.auth.PhoneAuthProvider();

    // 認証コードを発送する
    provider.verifyPhoneNumber(phoneNumber, this.appVerifier)
        .then((verificationId) => {
            this.verificationId = verificationId;
            console.log(verificationId);
            const msg = getTransMessage(this.translate, 'sendPhoneVerificationCode');
            this.showAlert("info", UtilFuncService.formatString(msg, phoneNumber));
            this.startCountdown(120); //120秒限制
        })
        .catch((error) => {
            //エラーメッセージ
            this.handleError(error);
            // 電話番号が間違った
            // this.showAlert("error", getTransMessage(this.translate, 'pleaseEnterAValidPhoneNumber'));
            console.log(error);
            this.sendcode = false;  // 发送失败时reset
        });
    }

    /**
     * 電話認証コードでログインのイベント
     */
    onPhoneNumberLogin(): void {
        this.phoneSubmitted = true;
        // if (this.profileForm.invalid) {
        //     return;
        // }

        if (this.isCodeExpired) {
            this.showAlert("error", getTransMessage(this.translate, "verification_code"));
            this.phoneSubmitted = false;
            return;
        }
        let _this = this;
        // 認証コード
        let verificationCode = this.phoneCode;
        // 認証処理
        let phoneCredential: firebase.auth.AuthCredential
        try {
            phoneCredential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, verificationCode);
        } catch (error) {
            this.handleError(error);
            this.phoneSubmitted = false;
            return;
        }

        console.log(phoneCredential);
        firebase.auth().signInWithCredential(phoneCredential)
            .then((result) => {
                this.doLogin(this.signInToken, result.user.email);
                // login successfully
                const user = result.user;
                console.log("User signed in successfully:", user);

                // 2FA(SMS)のcookie設定
                this._setExpireCookie();

                // バージョンアップなしの場合
                if (!this.signInToken.versionConfirm) {
                    this.router.navigate(['/dashboard']);
                    return;
                }
                // バージョンアップありの場合
                this.confirmVersion().then(url => {
                    this.router.navigate([url]);
                    return;
                });
            })
            .catch((error) => {
                this.handleError(error);
                console.error("Error during signInWithCredential:", error);
            });

        this.phoneSubmitted = false;
    }


    startCountdown(seconds: number) {
        this.countdown = seconds;
        this.isCodeExpired = false; // code Expired mark
        this.countdownInterval = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
            this.changeDetectorRef.detectChanges();
          } else {
            clearInterval(this.countdownInterval);
            this.sendcode = false;
            this.isCodeExpired = true; // code Expired timeout
            this.changeDetectorRef.detectChanges();
          }
        }, 1000);
      }
        /**
     * メールでログインイベント
     */
    onEmailLoginClick(): void {
        window.location.reload();
        // this.loginType = "email";
        // console.log(event);
        // setTimeout(() => this.verifyCodeService.show("verifyCode"), 0);
        // this.resetCheckCode();
    }

    /**
    * 二段階認証(SMS)クッキーを設定する 有効期間 = ConstantsHandler.SMS_EXPIRE_TOKEN.interval
    */
    _setExpireCookie() {
        const userInfo = JSON.parse(localStorage.getItem('profileUserInfo'));
        let currentTime = new Date().getTime();
        let timeout = new Date(currentTime + (ConstantsHandler.SMS_EXPIRE_TOKEN.interval));
        this.cookieService.set(`${ConstantsHandler.SMS_EXPIRE_TOKEN.id}_${userInfo.id}`, userInfo.phoneNumber, timeout);
    }

    handleError(error: any) {
        switch (error.code) {
            case 'auth/invalid-phone-number':
                this.showAlert("error", getTransMessage(this.translate, "phone_number_invalid"));
                break;
            case 'auth/missing-phone-number':
                this.showAlert("error",getTransMessage(this.translate, "phone_number_missing"));
                break;
            case 'auth/too-many-requests':
                this.showAlert("error",getTransMessage(this.translate, "requests_too_frequent"));
                break;
          // 处理其他错误代码...
          default:
            console.log(error);
            this.showAlert("error", getTransMessage(this.translate, "verification_code_incorrect"));
            break;
        }
      }
}
