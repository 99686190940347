import {Injectable} from "@angular/core";
import {LocalDataService} from "ifocus-lib";
import {ConstantsHandler} from "../_common/_constant/constants.handler";


@Injectable()
export class SelfUserInfoService {

    constructor(
        private localDataService: LocalDataService,
    ) {
    }

    public setSelfUserInfo(user: any): void {
        this.localDataService.set(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER, user);
    }

    public getSelfUserInfo() {
        return this.localDataService.get(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER);
    }

    public selfUserInfo(): any {
        const user = this.localDataService.get<any>(ConstantsHandler.LOCAL_STORAGE.PROFILE_USER);
        return user ? user : null;
    }

    public getLocaleList(): any[] {
        return this.localDataService.get(ConstantsHandler.LOCAL_STORAGE.LOCALE_LIST);
    }

    public setLocaleList(localeList: any[]): void {
        this.localDataService.set(ConstantsHandler.LOCAL_STORAGE.LOCALE_LIST, localeList);
    }

    public setLocaleInfo(localeInfo: any): void {
        this.localDataService.set("LOCALE_INFO", localeInfo);
    }

    public getLocaleInfo(): any {
        return this.localDataService.get("LOCALE_INFO");
    }

    public setTimezoneList(timezoneList: any[]): void {
        this.localDataService.set("TIMEZONE_LIST", timezoneList);
    }

    public getTimezoneList(): any[] {
        return this.localDataService.get("TIMEZONE_LIST");
    }


}
