import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class HelpModalService {

    // ダイアログ表示
    private show = new Subject<any>();
    public showCalled$ = this.show.asObservable();


    /**
     * 開く
     */
    public open(id: string): void {
        this.show.next(id);
    }


}
