import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { CommonConfirmDialogService } from '@services/common.confirm.dialog.service';
import { UtilFuncService } from 'ifocus-lib';
import { getTransMessage } from 'src/app/utils/CommonFunction';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsHandler } from '@constant/constants.handler';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.css']
})
export class TwoFactorAuthComponent implements OnInit {
  @Input() email: string;
  @Input() signInToken: any;
  @Output() doLoginEvent = new EventEmitter<{ tokenInfo: Object, email: string }>(); // Login

  verificationCode: string = ''; // ユーザーが入力している認証コード
  codeSend: boolean = false; // メール送信ボタンステータス
  loginPress: boolean = false; // 認証確認ボタン押下フラグ
  countdown: number = 0; // 時間 (s)
  countdownInterval: any;　// タイマー (clear用)
  emailEncode: string; // 画面表示用メール

  constructor(
    private authService: AuthService,
    private utilFuncService: UtilFuncService,
    protected translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private confirmationService: CommonConfirmDialogService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this._encodeEmail();
    this.sendCode();
  }

  /**
   * メールに2FAコードを送信
   */
  sendCode() {
    this.codeSend = true;
    /// 再発送ボタンのタイマー設定
    this.startCountdown(120);
    this.authService.send2FACode(this.email).subscribe({
      next: (response) => {
        if (response.success) {
          /// 成功メッセージフォーマット
          const msg = getTransMessage(this.translate, 'sendPhoneVerificationCode');　// に認証コードを発送しました
          const arg = getTransMessage(this.translate, 'mailAddr'); // メールアドレス
          const content = UtilFuncService.formatString(msg, arg);
          this.utilFuncService.showAlert("success", content, "", false, 5000);
        } else {
          const msg = getTransMessage(this.translate, 'sendingFailedPleaseTryAgainLater');
          this.utilFuncService.showAlert("error", msg, "", false, 10000);
        }
      },
      error: (error) => {
        const msg = getTransMessage(this.translate, 'sendingFailedPleaseTryAgainLater');
        this.utilFuncService.showAlert("error", msg, "", false, 10000);
        console.error('Error:', error);
      },
    });
  }

  /**
   * 2FAコードを検証
   */
  verifyCode() {
    if (this.email && this.verificationCode) {
      this.loginPress = true; // 二重クリック禁止
      this.authService.verify2FACode(this.email, this.verificationCode).subscribe({
        next: (response) => {
          this.loginPress = false;
          if (response.success) {
            this._setExpireCookie();
            this.doLoginEvent.emit({ tokenInfo: this.signInToken, email: this.email });
            // バージョンアップなしの場合
            if (!this.signInToken?.versionConfirm) {
              this.router.navigate(['/dashboard']);
              return;
            }
            // バージョンアップありの場合
            this.confirmVersion();
          } else {
            const content = getTransMessage(this.translate, "verification_code_incorrect");
            this.utilFuncService.showAlert("error", content, "", false, 5000);
            console.error('Error:', response);
          }
        },
        error: (error) => {
          this.loginPress = false;
          const content = getTransMessage(this.translate, "verification_code_incorrect");
          this.utilFuncService.showAlert("error", content, "", false, 5000);
          console.error('Error:', error);
        },
      });
    } else {
      const content = getTransMessage(this.translate, "verification_code_incorrect");
      this.utilFuncService.showAlert("error", content, "", false, 5000);
    }
  }

  /**
   * 戻るボタンイベント
   */
  onEmailLoginClick(): void {
    window.location.reload();
  }

  /**
   * 次回認証コードの送信可能までの時間
   * @param seconds ms
   */
  startCountdown(seconds: number) {
    this.countdown = seconds;
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.changeDetectorRef.detectChanges();
      } else {
        clearInterval(this.countdownInterval);
        this.codeSend = false;
        this.changeDetectorRef.detectChanges();
      }
    }, 1000);
  }


  /**
 * バージョン情報確認
 */
  private confirmVersion(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.confirmationService.confirm({
        message: getTransMessage(this.translate, "theLatestVersionHasBeenUpdatedWouldYouLikeToCheckOutTheNewFeatures") + getTransMessage(this.translate, "ifYouWantToCheckPleasePressTheConfirmButton"),
        header: getTransMessage(this.translate, "versionInfo"),
        accept: () => {
          localStorage.setItem('versionConfirm', 'T');
          return resolve('/profile');
        },
        reject: () => {
          return resolve('/dashboard');
        },
      });
    });
  }

  /**
   * 二段階認証クッキーの有効期間を設定する
   */
  _setExpireCookie() {
    const userInfo = JSON.parse(localStorage.getItem('profileUserInfo'));
    let currentTime = new Date().getTime();
    let timeout = new Date(currentTime + (ConstantsHandler.EMAIL_EXPIRE_TOKEN.interval));
    this.cookieService.set(`${ConstantsHandler.EMAIL_EXPIRE_TOKEN.id}_${userInfo.id}`, this.email, timeout);
  }

  /**
   * メールアドレス暗証化（画面表示用）
   */
  _encodeEmail(): void {
    const [user, domain] = this.email.split('@');
    var maskedUser: string = user;
    var maskedDomain: string = domain;

    if (user && user.length > 4) {
      const start = user.slice(0, 2);  // 最初の2文字
      const end = user.slice(-2);      // 最後の2文字
      const middle = '*'.repeat(user.length - 4);  // 真ん中を'*'で隠す

      maskedUser = start + middle + end;
    }
    this.emailEncode = maskedUser + '@' + maskedDomain;
  }

}


