import {Injectable} from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class MapMarkerService {

    public constructor() {
    }

    /**
     * 条件フラグによって、位置を設定する
     * @param marker marker
     * @param info info バイタル情報
     * @param userListBackup ユーザー情報
     * @param onlineFlag オンラインフラグ
     * @param warnFlag 警告フラグ
     * @param markerInfo 戻り値
     */
    public setMarkerByFlag(marker, info, userListBackup, onlineFlag: boolean, warnFlag: boolean, markerInfo: MarkerInfo) {
        if (!onlineFlag && !warnFlag) {
            markerInfo.markers.push(marker);
        } else {
            // オンラインユーザー
            let onLine = userListBackup.filter(function (d) {
                return d.status === "10";
            });
            // オンラインデバイス
            onLine = onLine.map(u => u.imei);
            let onLineDevice = onLine.includes(info.imei);

            // 警告あり
            let warn = userListBackup.filter(user => user.todayAlertCount > 0);
            warn = warn.map(u => u.userId);
            // 警告ありユーザー
            let warnUser = warn.includes(info.userId);

            if (onlineFlag && warnFlag) {
                if (onLineDevice && warnUser) {
                    markerInfo.markers.push(marker);
                }
            } else if (onlineFlag && onLineDevice) {
                markerInfo.markers.push(marker);
            } else if (warnFlag && warnUser) {
                markerInfo.markers.push(marker);
            }
        }
    }

    /**
     * オンラインフラグ・警告フラグによって、ユーザーをフィルターする
     */
    public doFilterUserList(filter: FilterUserInfo): void {
        if (!filter.userListBackup) {
            filter.userListBackup = Object.assign([], filter.userList);
        }
        // オンライン判断
        if (filter.onlineFlag) {
            filter.userList = filter.userList.filter(function (d) {
                return d.status === "10";
            });
        }
        // 警告判断
        if (filter.warnFlag) {
            filter.userList = filter.userList.filter(user => user.todayAlertCount > 0);
        }
        // 着用判断
        if(filter.wearingFlag){
            filter.userList = filter.userList.filter(user => user.wearing === true);
        }
        // 充電判断
        if(filter.chargingFlag){
            filter.userList = filter.userList.filter(user => user.isCharging === true);
        }
    }

    /**
     * 閾値により、このユーザー状態が範囲外となるかどうか
     * @param userList ユーザー情報
     * @param info ユーザーバイタル情報
     * @param userData ユーザー情報
     * @param alertInfoList 今日のアラート通知情報
     */
    public isWarning(userList, info, userData, alertInfoList): boolean {
        // 日付によって、該当なユーザーのアラート通知判断
        let curUser = userList.filter(user => user.userId === userData.userId);
        return curUser && curUser.length > 0 && curUser[0].todayAlertCount && curUser[0].todayAlertCount > 0;
    }

    /**
     * ソート処理
     * @param ascending
     * @param nm キー
     */
    public alphabetically(ascending, nm) {
        return function (a, b) {
            // equal items sort equally
            if (a[nm] === b[nm]) {
                return 0;
            }
            // nulls sort after anything else
            else if (a[nm] === null) {
                return 1;
            } else if (b[nm] === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (ascending) {
                return a[nm] < b[nm] ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a[nm] < b[nm] ? 1 : -1;
            }
        };
    }

    /**
     * オンライン／オフライン設定
     * @param online フラグ
     */
    public statusDisplay(online: any) {
        if (online === true) {
            return "10";
        } else if (online === false) {
            return "20";
        } else {
            return "30";
        }
    }

    /**
     * バッテリー設定
     * @param batteryPercentage 数値
     */
    public batteryDisplay(batteryPercentage: any) {
        if (batteryPercentage > 60) {
            return "fullBattery";
        } else if (batteryPercentage > 30) {
            return "commonBattery";
        } else if (batteryPercentage > 0) {
            return "lowBattery";
        } else {
            return "noData";
        }
    }

}

interface FilterUserInfo {
    userListBackup: any[];
    userList: any[];
    onlineFlag: boolean;
    warnFlag: boolean;
    wearingFlag: boolean;
    chargingFlag: boolean;
}

interface MarkerInfo {
    markers: any[];
}

/**
 * marker情報
 */
export interface Marker {
    lat: number;
    lng: number;
    label: string;
    icon: string;
    info: {
        imei: string;
        userName: string;
        deviceName: string;
        bodyTemperature: string;
        skinTemperature: string;
        enviromentTemperature: string;
        temperatureTimestamp: string;
        heartRate: string;
        heartRateTimestamp: string;
        systolicBloodPressureValue: string;
        diastolicBloodPressureValue: string;
        bloodPressureTimestamp: string;
        bloodOxygen: string;
        bloodOxygenTimestamp: string;
        stepCount: string;
        stepCountsTimestamp: string;
        address: string;
        addressTimestamp: string;
        timestamp: string;
        modelType?:any;
    };
    isSelected: boolean;
}

export class UserMarkerInfo {
    userData: any;
    marker: Marker;
}
