import {Injectable} from '@angular/core';
import {HttpService} from './HttpService';
import firebase from 'firebase';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {LanguageService, UtilFuncService} from 'ifocus-lib';
import {TranslateService} from "@ngx-translate/core";
import {getTransMessage} from "../utils/CommonFunction";

@Injectable({
    providedIn: 'root'
})
export class WebNotificationMessageService {

    angularFireMessaging: firebase.messaging.Messaging;

    messageSwRegitration: any;
    firebaseMessageRegistrationToken: string;

    constructor(private httpService: HttpService,
                private utilFuncService: UtilFuncService,
                private _router: Router,
                protected language: LanguageService,
                protected translate: TranslateService
    ) {
        // firebase message初期化
        if (firebase.messaging.isSupported()) {
            this.angularFireMessaging = firebase.messaging();
            this.angularFireMessaging.onMessage((payload) => {
                this.onMessageCallback(payload);
            });
        }
        ;
    }

    onMessageCallback(payload: any) {
        console.log('Message received. ', payload);
        if (!payload.notification) {
            return;
        }
        var notification = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: 'favicon.ico'
        })
        notification.onclick = (event) => {
            console.log("onMessage, notification click", event);
            this._router.navigate(['/alert-notifications']);
        }
    }

    async register(): Promise<void> {
        if (this.angularFireMessaging == null) { // unsupport browser
            return;
        }
        if (!('Notification' in window)
            || !('serviceWorker' in navigator)
            || !("PushManager" in window)) {
            this.utilFuncService.showAlert("warn", getTransMessage(this.translate, "notificationsAreNotSupportedInThisBrowser"));
            return;
        }

        var permission = Notification.permission;
        if (permission != "granted") {
            permission = await Notification.requestPermission();
        }
        if (permission != "granted") {
            this.utilFuncService.showAlert("info", getTransMessage(this.translate, "notificationsAreNotSupportedInThisBrowser"));
            return;
        }

        // service workerとの連携を初期化
        this.messageSwRegitration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        setTimeout(async () => {
            this.messageSwRegitration.update();
            this.firebaseMessageRegistrationToken = await this.angularFireMessaging.getToken({
                vapidKey: environment.firebase.vapidKey,
                serviceWorkerRegistration: this.messageSwRegitration,
            });
            await this.httpService.usePost("user-notification/registry", {
                registrationType: 'WEB',
                registrationToken: this.firebaseMessageRegistrationToken
            }).catch(err => {
                this.utilFuncService.showAlert("error", getTransMessage(this.translate, "failedToSetUpNotifications"));
            });
        }, 500);

        // this.messageSwRegitration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        // navigator.serviceWorker.ready.then(reg => {
        //     reg.update();
        // });
        // this.firebaseMessageRegistrationToken = await this.angularFireMessaging.getToken({
        //     vapidKey: environment.firebase.vapidKey,
        //     serviceWorkerRegistration: this.messageSwRegitration,
        // });
        // await this.httpService.usePost("user-notification/registry", {
        //     registrationType: 'WEB',
        //     registrationToken: this.firebaseMessageRegistrationToken
        // }).catch(err => {
        //     this.utilFuncService.showAlert("error", getTransMessage(this.translate, "failedToSetUpNotifications"));
        // });

    }

    async unregister(): Promise<void> {

        if (this.angularFireMessaging == null) {  // unsupport browser
            return new Promise((resolve, reject) => {
                resolve();
            }); // do nothing
        }

        var registrations = await navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
            registration.unregister();
            console.log("sw registration unregistered.", registration);
        }

        await this.httpService.usePost("user-notification/unregistry", {
            registrationType: 'WEB',
            registrationToken: this.firebaseMessageRegistrationToken
        }).catch(err => {
            this.utilFuncService.showAlert("error", getTransMessage(this.translate, "failedToRemoveNotificationSettings"));
        });

    }


}
