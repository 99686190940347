import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';
import { ConstantsHandler } from '../_common/_constant/constants.handler';
import { WebNotificationMessageService } from './web-notification-message.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {

  // Firebase FunctionsのURL
  private send2FAUrl = 'https://us-central1-aaaswatch.cloudfunctions.net/send2FACode';
  private verify2FAUrl = 'https://us-central1-aaaswatch.cloudfunctions.net/verify2FACode ';

  constructor(public afAuth: AngularFireAuth,private cookieService: CookieService,
    private webNotificationMessageService: WebNotificationMessageService, private http: HttpClient) {}

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Logout
  doLogout() {
    return new Promise<void>(async (resolve, reject) => {
      await this.webNotificationMessageService.unregister();
      if (firebase.auth().currentUser) {
        localStorage.removeItem('currentUser');
        // localStorage.removeItem('remember');
        this.afAuth.auth.signOut();
        resolve();
      } else {
        localStorage.removeItem('currentUser');
        resolve();
      }
      this.cookieService.delete(ConstantsHandler.GLOBAL_TOKEN.id)
      this.cookieService.delete(ConstantsHandler.TOKEN.cookieName)
    });
  }

  /**
   * 2FAコードを送信する
   * @param email 
   * @returns Observable<any>
   */
  send2FACode(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.send2FAUrl, { email }, { headers });
  }

  /**
   * 2FAコードを検証する
   * @param email メール
   * @param code 認証コード
   * @returns Observable<any>
   */
  verify2FACode(email: string, code: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.verify2FAUrl, { email, code }, { headers });
  }

}
