import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceMessageComponent} from './device-message/device-message.component';
import {DeviceMessageMyComponent} from './device-message-my/device-message-my.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {IfcsTableModule} from "ifocus-lib";
import {IfcsLinkModule} from "ifocus-lib";
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
    exports: [DeviceMessageComponent, DeviceMessageMyComponent],
    declarations: [DeviceMessageComponent, DeviceMessageMyComponent],
    imports: [
        CommonModule,
        NgbPaginationModule,
        FormsModule,
        IfcsTableModule,
        IfcsLinkModule,
        TranslateModule,
    ]
})
export class DeviceMessageModule {
}
