<form>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">{{'bulkSendMessagesToRecipients' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="deviceSelDlg" class="input-group">
            <ifcs-link [label]="tosLabel" (clickEvent)="onAddWatch(deviceModal)" [linkClass]="tosLinkClass"
                       iconClass="feather ft-watch" style="width: 100%;"></ifcs-link>
        </div>

        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">
                    <i class="la la-envelope"></i>
                </span>
            </div>
            <input type="text" placeholder="{{'pleaseEnterTheMessageContent' | translate}}" class="form-control" [(ngModel)]="messageToBeSend"
                   [ngModelOptions]="{standalone: true}"/>
            <div class="input-group-append sendMessageButton" (click)="sendMessageToMultipleDevice()" title="{{'sendMessage' | translate}}">
                <span class="input-group-text">
                    <i class="la la-send"></i>
                </span>
            </div>
        </div>

        <h6 class="form-section" style="margin-top: 30px;">
            <i class="icon-speech"></i> {{'messageSendingHistory' | translate}}
        </h6>
        <div class="table-responsive">
            <table class="table grid-breakpoints" style="margin-bottom: 0.3rem;">
                <thead>
                <tr>
                    <th class="thcolor">No.</th>
                    <th scope="col" class="thcolor">{{'sendingDateAndTime' | translate}}</th>
                    <th scope="col" class="thcolor">{{'messageContent' | translate}}</th>
                    <th scope="col" class="thcolor">{{'sender' | translate}}</th>
                    <th scope="col" class="thcolor">{{'status' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let element of messageList.list;let i = index">
                    <td>{{ (messageList.pageNumber - 1) * messageList.pageSize + i + 1 }}</td>
                    <td>{{ element.operatedTimestamp }}</td>
                    <td>{{ element.msgContent }}</td>
                    <td>{{ element.targetDeviceName }} ({{ element.imei }})</td>
                    <td>
                        <span *ngIf="element.isSentSuccess">{{'sentSuccessfully' | translate}}</span>
                        <span *ngIf="!element.isSentSuccess">{{'sendingFailed' | translate}}</span>
                    </td>
                </tr>
                </tbody>
                <colgroup>
                    <col width="2%">
                    <col width="25%">
                    <col width="36%">
                    <col width="35%">
                    <col width="2%">
                </colgroup>
            </table>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12" style="display: flex; align-items: center;">
                <div *ngIf="messageList.total <= messageList.pageSize">{{ messageList.total }} {{'total0' | translate}}</div>
                <div *ngIf="messageList.total > messageList.pageSize">{{ messageList.total }}
                    {{'totalPage' | translate}} {{ (messageList.pageNumber - 1
                    )
                    *
                    messageList.pageSize + 1}} ~ {{
                    (messageList.pageNumber - 1) * messageList.pageSize
                    + messageList.list.length }} {{'currentPage' | translate}}
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="display: flex; align-items: center; justify-content: center;">
                <ngb-pagination [collectionSize]="messageList.total" [(page)]="messageList.pageNumber"
                                [pageSize]="messageList.pageSize" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"
                                [directionLinks]=false [ellipses]=false
                                (pageChange)="onMyMessageListPageChange($event)">
                </ngb-pagination>
            </div>
            <div class="col-lg-4 col-md-12" style="display: flex; align-items: center; justify-content: flex-end;">
                <select class="custom-select" style="width: auto" [(ngModel)]="messageList.pageSize"
                        (change)="onMyMessageListPageChange(messageList.pageNumber)"
                        [ngModelOptions]="{standalone: true}">
                        <option value="5">5{{'itemsPerPage' | translate}}</option>
                        <option value="10">10{{'itemsPerPage' | translate}}</option>
                        <option value="20">20{{'itemsPerPage' | translate}}</option>
                        <option value="50">50{{'itemsPerPage' | translate}}</option>
                        <option value="100">100{{'itemsPerPage' | translate}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="form-group position-relative has-icon-left mb-0">
            <button type="button" class="btn btn-secondary mr-1" (click)="closeModal()">{{'close' | translate}}</button>
        </div>
    </div>
</form>


<!--送信先一覧-->
<ng-template #deviceModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{'recipientList' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="form-group col-12">
                <div class="table-responsive">
                <ifcs-table tblId="device_message_my_device_list" [list]="deviceList" [pageable]="true" [checkable]="true">
                    <ul class="header device_message_my_device_list">
                        <li data-col="auto" data-text="No." data-type="auto"></li>
                        <li data-col="deviceName" data-text="デバイス名" data-sortable="true"></li>
                        <li data-col="imei" data-text="IMEI" data-sortable="true"></li>
                        <li data-col="bindingUserName" data-text="利用者" data-sortable="true"></li>
                    </ul>
                </ifcs-table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn grey btn-outline-secondary" (click)="c('Close modal')" ngbAutofocus>{{'cancel' | translate}}</button>
        <button class="btn btn-outline-primary" (click)="onSelectClick()">{{'select' | translate}}</button>
    </div>
</ng-template>

