import {LanguageService} from "ifocus-lib";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {TranslateService} from "@ngx-translate/core";


export function getTransMessage(translate: TranslateService, key: string): string {
    return LanguageService.getTransMessage(translate, key);
}

export function getDefaultLanguage(translate: TranslateService): string {
    return LanguageService.getDefaultLanguage(translate, ConstantsHandler.multiLanguage);
}


export function round(num: number, digit: number) {
    return Math.round(num * (10 ** digit)) / (10 ** digit);
};

export function round0(num: number) {
    return round(num, 0);
};
export function round1(num: number) {
    return round(num, 1);
};
export function round2(num: number) {
    return round(num, 2);
};
export function round3(num: number) {
    return round(num, 3);
};
export function round4(num: number) {
    return round(num, 4);
};
export function round5(num: number) {
    return round(num, 5);
};
export function round6(num: number) {
    return round(num, 6);
};
export function round7(num: number) {
    return round(num, 7);
};
