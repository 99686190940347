<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0"
                            style="background-image: url(../../assets/custom/images/signin-background1.png);">
                            <div class="card-header border-0"
                                style="background-image: url(../../assets/custom/images/signin-background1.png);">
                                <div class="card-title text-center">
                                    <img src="assets/custom/images/logo.png" alt="branding logo" width="40%"
                                        height="40%">
                                </div>
                            </div>
                            <div class="card-title text-center">
                                <h1 style="color:white;">AAASWatch</h1>
                            </div>
                            <div class="card-content">
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <!-- <span>OR Using Email</span> -->
                                </p>
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="registerForm" (ngSubmit)="tryRegister()">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="email" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                placeholder="Your Email Address">
                                            <div class="form-control-position">
                                                <i class="feather ft-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                id="user-password" placeholder="パスワード" required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" formControlName="confirmpassword"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }"
                                                id="confirmpassword" placeholder="パスワード（確認用）" required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmpassword.errors.required">Confirm Password is
                                                    required</div>
                                                <div *ngIf="f.confirmpassword.errors.minlength">Confirm Password must be
                                                    at least 6
                                                    characters</div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class="card-body">
                                    <a [routerLink]="['/login']" class="btn btn-outline-danger btn-block"><i
                                            class="feather ft-unlock"></i> ログイン</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>