<div (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" id="main-menu"
	class="main-menu menu-fixed menu-light menu-accordion menu-shadow menu-collapsible" data-scroll-to-active="true">
	<div id="main-menu-content" class="main-menu-content ps-container ps-theme-light" fxFlex="auto"
		[perfectScrollbar]="config">
		<ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
			<!-- Menu -->
			{{child?child.title:''}}
			<li *ngFor="let child of _menuSettingsConfig.vertical_menu.items" class="" [ngClass]="{
            'has-sub': child.submenu,
            'open': child.isOpen && child.submenu,
            'nav-item': child.title,
            'navigation-header':child.section,
            'active': child.isSelected && !child.submenu,
            'menu-collapsed-open': child.isSelected && child.submenu,
            'hover': child.hover
          }" title="{{child.section | translate}}">
				<!-- Section -->
				<span class="menu-title" *ngIf="child.section"><b translate>{{child.section}}</b></span>
				<span class="menu-title" *ngIf="child.section"><hr/></span>
				<i class="la" *ngIf="child.section" [ngClass]="child.icon" data-toggle="tooltip" data-placement="right"
					data-original-title="Support"></i>

				<!-- Root Menu -->
				<!-- Root Menu クリックなし-->
				<a *ngIf="!child.click && child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink && !child.isStarterkitExternalLink"
					routerLink="{{child.page != 'null'?child.page:router.url}}" (click)="toggleMenu($event, child)" title="{{child.title | translate}}">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="" translate>{{child.title}}</span>
					<span *ngIf="child.badge" class="badge badge-pill float-right"
						[ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}" translate>
						{{child.badge.value}}
					</span>
				</a>
				<!-- Root Menu クリックあり-->
				<a *ngIf="child.click && child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink && !child.isStarterkitExternalLink"
				   href="javascript:void(0)" (click)="toggleMenu($event, child)" title="{{child.title | translate}}">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="" translate>{{child.title}}</span>
					<span *ngIf="child.badge" class="badge badge-pill float-right"
						  [ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}" translate>
						{{child.badge.value}}
					</span>
				</a>

				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && child.isExternalLink"title="{{child.title | translate}}"
          [href]="child.page"  target="_blank" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="" translate>{{child.title}}</span>
					<span *ngIf="child.badge" class="badge badge-pill float-right"
						[ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}" translate>
						{{child.badge.value}}
					</span>
				</a>
				<a *ngIf="child.title && !child.submenu && !child.excludeInVertical && child.isStarterkitExternalLink" title="{{child.title | translate}}"
          [href]="child.page"  target="_blank" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="" translate>{{child.title}}</span>
					<span *ngIf="child.badge" class="badge badge-pill float-right"
						[ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}" translate>
						{{child.badge.value}}
					</span>
				</a>


				<!-- Submenu -->
				<a *ngIf="child.title && child.submenu && !child.excludeInVertical" title="{{child.title | translate}}"
					routerLink="{{child.page != 'null'?child.page:router.url}}" (click)="toggleMenu($event, child)">
					<i class="la" [ngClass]="child.icon"></i>
					<span class="menu-title" data-i18n="" translate>{{child.title}}</span>
					<span *ngIf="child.badge" class="badge badge-pill float-right"
						[ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}" translate>
						{{child.badge.value}}
					</span>
				</a>
				<ul *ngIf="child.submenu" class="menu-content" [@popOverState]="child.isOpen">
          <!-- Submenu of Submenu -->
					<li *ngFor="let subchild of child.submenu.items" class="isShown"
						[ngClass]="{'has-sub':(subchild.submenu),'active': subchild.isSelected && !subchild.submenu, 'open': subchild.isOpen && subchild.submenu}">
						<a class="menu-item" *ngIf="!subchild.submenu && !subchild.excludeInVertical" (click)="toggleMenu($event, subchild, true)" title="{{subchild.title | translate}}"
							routerLink="{{subchild.page != 'null'?subchild.page:router.url}}" translate>{{subchild.title}}</a>
						<a class="menu-item" *ngIf="subchild.submenu && !subchild.excludeInVertical" (click)="toggleMenu($event, subchild, true)" title="{{subchild.title | translate}}"
							routerLink="{{subchild.page != 'null'?subchild.page:router.url}}" translate>{{subchild.title}}</a>
						<ul *ngIf="subchild.submenu && !subchild.excludeInVertical" class="menu-content">
							<li *ngFor="let subchild of subchild.submenu.items" [ngClass]="{'active': subchild.isSelected && !subchild.submenu}">
								<a class="menu-item" *ngIf="!subchild.event" title="{{subchild.title | translate}}"
									routerLink="{{subchild.page}}" (click)="toggleMenu($event, subchild, true)" translate>{{subchild.title}}</a>
								<a class="menu-item" *ngIf="subchild.event"
									(click)="callFunction(subchild.event, subchild)" translate title="{{subchild.title | translate}}">{{subchild.title}}</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</div>

<!-- ヘルプダイアログ-->
<app-help-modal helpId="menu_manual_modal" helpIdpro="menu_manual_modal_pro" helpIdNotpro="menu_manual_modal_Notpro" helpIdDevice="menu_manual_modal_Device"></app-help-modal>
