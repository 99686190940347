<div class="card-body">
    <span class="text-white" translate>{{'verificationEmail'}}</span>
    <fieldset class="form-group position-relative has-icon-left mb15">
        <input type="text" class="form-control" [(ngModel)]="emailEncode" readonly placeholder="email" required>
        <div class="form-control-position">
            <i class="la la-user"></i>
        </div>
    </fieldset>
    <fieldset class="form-group position-relative has-icon-left mb15 d-flex">
        <input class="form-control" type="text" id="verificationCode" [(ngModel)]="verificationCode" placeholder="{{'authenticationCode' | translate}}"
            style="border-bottom-right-radius: 0%; border-top-right-radius: 0%;" name="verificationCode" required>
        <div class="form-control-position">
            <i class="la la-lock"></i>
        </div>
        <button class="form-control btn btn-primary p0" (click)="sendCode()" [disabled]="codeSend">
            <span translate class="row">
                <div>
                    <i class="feather ft-mail"></i>
                </div>
                <span translate>&nbsp;{{'sendVerificationCode'}}</span>
                <div *ngIf="codeSend">({{ countdown }})</div>
            </span>
        </button>
    </fieldset>
    <fieldset class="form-group position-relative has-icon-left">
        <button (click)="verifyCode()" [disabled]="loginPress" class="btn btn-outline-info btn-block">
            <i class="fa fa-refresh fa-spin" *ngIf="loginPress"></i>
            <i class="feather ft-unlock" *ngIf="!loginPress"></i>
            <span translate>&nbsp;{{'login'}}</span>
        </button>
    </fieldset>
    <fieldset class="form-group position-relative has-icon-left">
        <button (click)="onEmailLoginClick()" class="btn btn-outline-info btn-block">
            <span translate>&nbsp;{{'back'}}</span>
        </button>
    </fieldset>
</div>