// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firebase and google map related functionality will not work on LIVE instance.

export const environment = {
	production: false,

	// デフォルト言語
	multiLanguage: {
		supportLangs: ['en','ja', 'cl'],
		defaultLang: 'en',
	},

	firebase: {
		apiKey: "AIzaSyA-HEBAAvYgsuMnAL2Vb0U4XT-wAe3HuGs",
		authDomain: "aaaswatch.firebaseapp.com",
		projectId: "aaaswatch",
		storageBucket: "aaaswatch.appspot.com",
		messagingSenderId: "38561433211",
		appId: "1:38561433211:web:e86cbdbaa0c0021f3684de",
		vapidKey:
			"BODxcCaKUF7ppmoJhZSNuUqh9iXY1bk-j7ajoqpiECp-74tiv8utLIWUTQJQvRKsxTOFolzdANdJQtORyTlqfsw",
	},
	googleApiKey: "AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU",
	accUrl: {
		server: "http://localhost:8080",
		bsServer: "https://demo.trackun.jp/bs",
	},
	keycloak: {
		server: "https://auth.aaaswatch.io/auth/realms/trackun/",
		clientId: "trackun",
	},
	targetUser: {
		id: "targetuserid",
		companyId: "targetuserCompanyid",
	},

	///////////////// その他 /////////////////
	other: {
		LOCAL_STORAGE: {
			PROFILE_USER: 'LOCAL_PROFILE_USER',
			LOCALE_LIST: 'LOCALE_LIST',
			CURRENT_LANG: 'CURRENT_LANG',
		},

	},

	httpEnv: {
		// // サーバー（ローカル）
		// watchServer: 'http://localhost:9002/',
		// watchServer: "https://api.aaaswatch.io/",
		watchServer: "https://dev.aaaswatch.io/api/",
	},
};
