<!-- ヘルプダイアログ-->
<ifcs-modal [modalId]="helpIdpro"
            title="{{'help' | translate}}"
            bodyClass="class-body" footerClass="class-footer"
            cancelBtnText="{{'close' | translate}}" okBtnText="{{'save' | translate}}"
            (okClick)="onSaveClickpro()"
            (cancelClicked)="onCancelClickpro()">
    <div class="modalBody">
        <pdf-viewer [src]="helpDoc"
                    [render-text]="true"
                    style="display: block;height: 700px;">
        </pdf-viewer>
    </div>
</ifcs-modal>

<ifcs-modal [modalId]="helpIdNotpro"
            title="{{'help' | translate}}"
            bodyClass="class-body" footerClass="class-footer"
            cancelBtnText="{{'close' | translate}}" okBtnText="{{'save' | translate}}"
            (okClick)="onSaveClickNotpro()"
            (cancelClicked)="onCancelClickNotpro()">
    <div class="modalBody">
        <pdf-viewer [src]="helpDoc"
                    [render-text]="true"
                    style="display: block;height: 700px;">
        </pdf-viewer>
    </div>
</ifcs-modal>

<ifcs-modal [modalId]="helpIdDevice"
            title="{{'help' | translate}}"
            bodyClass="class-body" footerClass="class-footer"
            cancelBtnText="{{'close' | translate}}" okBtnText="{{'save' | translate}}"
            (okClick)="onSaveClickDevice()"
            (cancelClicked)="onCancelClickDevice()">
    <div class="modalBody">
        <pdf-viewer [src]="helpDoc"
                    [render-text]="true"
                    style="display: block;height: 700px;">
        </pdf-viewer>
    </div>
</ifcs-modal>

<ifcs-modal [modalId]="helpId"
            title="{{'help' | translate}}"
            bodyClass="class-body-select" footerClass="class-footer"
            okBtnHidden="true" cancelBtnText="{{'close' | translate}}"
            (cancelClicked)="onSelectCancelClick()">
    <div class="modalBody">
        <div>
            <button type="button" class="btn btn-secondary mr-1 mb-1" (click)="onSelectCancelClick();showHelpFile('notpro')">
                <span translate>watchManual</span>
            </button>
            <button type="button" class="btn btn-secondary mb-1" (click)="onSelectCancelClick();showHelpFile('pro')">
                <span translate>watchProManual</span>
            </button>
            <br>
            <button type="button" class="btn btn-secondary " (click)="onSelectCancelClick();showHelpFile('device')">
                <span translate>deviceManual</span>
            </button>
        </div>
    </div>
</ifcs-modal>
