import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {HttpService} from 'src/app/_services/HttpService';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IfcsLoadingUIService, IfcsModalService, UtilFuncService, IfcsComponentBase, LanguageService, IfcsTableService} from "ifocus-lib";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultLanguage, getTransMessage } from "../../../../utils/CommonFunction";
@Component({
    selector: 'app-device-message-my',
    templateUrl: './device-message-my.component.html',
    styleUrls: ['./device-message-my.component.css']
})
export class DeviceMessageMyComponent extends IfcsComponentBase implements OnInit, OnDestroy {

    @Input() selectedRows: any[];
    @Output() closeEvent = new EventEmitter();
    @Input() deviceSelDlg: boolean = false;

    tosLabel: string = getTransMessage(this.translate, "pleaseSelectARecipient"); 
    tosLinkClass: string = "btn btn-social btn-block mb-1 btn-outline-flickr";

    messageToBeSend: string;
    messageList: any = {
        total: 0,
        pageNumber: 1,
        pageSize: 5,
        list: [],
    };

    constructor(private httpService: HttpService,
                private utilFuncService: UtilFuncService,
                public loader: LoadingBarService,
                public loadingService: IfcsLoadingUIService,
                private modal: NgbModal,
                protected language: LanguageService,
                protected translate: TranslateService,
                private tableService: IfcsTableService,
    ) {
        // 多言語対応
        super(language, translate);

    }

    ngOnInit(): void {
        setTimeout(() => {
            if (this.deviceSelDlg) {
                this.initDeviceList();
            }
            this.getMyMessageList();
        });
    }

    ngOnDestroy(): void {
        this.loader.complete()
        this.loadingService.complete();
    }

    async getMyMessageList() {
        this.loader.start();
        this.loadingService.start();

        var page = await this.httpService.useGet(
            "downlinkMessage/myList?pageSize=" + this.messageList.pageSize
            + "&pageNumber=" + this.messageList.pageNumber);
        this.messageList.total = page.total;
        this.messageList.list = page.currentPage;

        this.loader.complete();
        this.loadingService.complete();
    }

    async sendMessageToMultipleDevice() {

        if (this.selectedRows == null || this.selectedRows.length == 0) {
            this.utilFuncService.showAlert('warn', getTransMessage(this.translate, "pleaseSelectARecipient"));
            return;
        }

        // 送信先ダイアログありの場合
        if (this.deviceSelDlg) {
            // 選択した送信先をクリアする
            this.deviceList.forEach(item => item.selected = false);
        }

        // loop send message
        this.loader.start();
        this.loadingService.start();

        var index = 0;
        while (index < this.selectedRows.length) {
            var row = this.selectedRows[index++];
            try {
                await this.sendMessageToDevice(row);
            } catch (error) {
                if (error.message == 'MESSAGE_EMPTY') {
                    this.loader.complete();
                    this.loadingService.complete();
                    return;
                }
            }
        }
        await this.getMyMessageList(); // refresh my message list

        this.loader.complete();
        this.loadingService.complete();

        this.messageToBeSend = null;
    }

    async sendMessageToDevice(row: any) {

        if (this.messageToBeSend == null || this.messageToBeSend.length == 0) {
            this.utilFuncService.showAlert('warn', getTransMessage(this.translate, "pleaseEnterAMessage"));
            throw new Error("MESSAGE_EMPTY");
        }

        if (row.imei == null || row.imei == '') {
            return;
        }

        try {
            var result = await this.httpService.usePost("downlinkMessage/" + row.imei + "/send", {
                message: this.messageToBeSend
            });
            if (result) {
                this.utilFuncService.showAlert('success', getTransMessage(this.translate, "messageSentSuccessfully") +"("+getTransMessage(this.translate, "deviceName")+"=" + row.deviceName + ", imei=" + row.imei + ")");
            } else {
                this.utilFuncService.showAlert('error', getTransMessage(this.translate, "messageSendingFailed")+"("+getTransMessage(this.translate, "deviceName")+"=" + row.deviceName + ", imei=" + row.imei + ")"+getTransMessage(this.translate, "isTheDeviceOnline"));
            }
        } catch (e) {
            console.log(e);
            // console.log('メッセージ送信　APIエラー発生しました。');
        }

    }

    onMyMessageListPageChange(targetPageNumber: number) {
        this.messageList.list = [];
        this.messageList.pageNumber = targetPageNumber;
        this.getMyMessageList();
    }

    closeModal(): void {
        this.closeEvent.emit(null);
    }


    deviceList: any[];
    deviceListModal = null;

    initDeviceList() {
        this.deviceList = [];
        this.httpService.useGet('watch/listWithUserBasic').then(result => {
            try {
                if (result) {
                    this.deviceList = result.currentPage;
                }
            } catch (e) {
                console.log(e);
                // console.log('利用者数数を検索API エラー　発生しました。');
            }
        })
    }

    onAddWatch(modalContent): void {
        this.deviceListModal = this.modal.open(modalContent, {
            windowClass: 'animated fadeInDown',
            size: 'lg',
            centered: true
        });
        // 再描画処理
        this.tableService.repaint("device_message_my_device_list");
    }

    onSelectClick(): void {
        this.tosLabel = "";
        this.selectedRows = this.deviceList.filter(item => {
            if (item.selected) {
                this.tosLabel = this.tosLabel.concat(item.deviceName).concat(" | ");
            }
            return item.selected;
        });
        if (this.tosLabel.length == 0) { // choose nothing
            this.tosLabel = getTransMessage(this.translate, "pleaseSelectARecipient");
            this.tosLinkClass = "btn btn-social btn-block mb-1 btn-outline-flickr";
        }else{
            this.tosLabel = this.tosLabel.substring(0, this.tosLabel.length - 3); // clear the last seperator
            this.tosLinkClass = "btn btn-social btn-block mb-1 btn-outline-flickr btn-outline-custom"
        }
        this.deviceListModal.close();
    }

}
